/* Card4.css */

.blog-section {
  display: flex;
  align-items: center;
}

@media screen and (min-device-width: 1200px) and (max-device-width: 1400px) {
  .gap-140 {
    gap: 5rem;
  }

  .gap-150 {
    gap: 6rem;
  }

  .pr-1200 {
    padding-right: 2rem;
  }

  .pr-6 {
    padding-right: 0rem;
  }
}

.p-explore {
  padding: 2rem;
}

@media screen and (min-device-width: 992px) and (max-device-width: 1199px) {

  .flex-col {
    flex-direction: column !important;
  }

  /* .grid-cols-3 {
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr)) !important;
  } */

  .gap-\[15rem\] {
    gap: 0rem;
  }

  .gap-\[15rem\] {
    gap: 0rem;
  }

  .blogimg {
    display: inline-block;
  }
}

@media screen and (max-width: 992px) {
  .pl-m24 {
    padding-left: 2rem;
  }

  .flex-col {
    flex-direction: column !important;
  }

  /* .grid-cols-3 {
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr)) !important;
  } */
}


@media screen and (max-width: 992px) {

  .blocksection {
    display: block !important;
  }

  .pl-m24 {
    padding-left: 0rem !important;
  }

  .p-m12 {
    padding: 2rem;
  }

  .pt-m20 {
    padding-top: 20px;
  }

  .m-pt-m20 {
    padding-top: 5px;
  }

  .gap-\[15rem\] {
    gap: 2rem;
  }

  .gap-140 {
    gap: 2rem;
  }

  .p-8 {
    padding: 1rem;
  }

  .pl-32 {
    padding-left: 0rem;
  }

  .blogimg {
    display: inline-block;
  }

  .blog-section {
    display: block;
    align-items: center;
  }

  .pr-64 {
    padding-right: 7rem;
  }

  .pl-8 {
    padding-left: 1rem;
    padding-bottom: 25px;
  }

  .pall-80 {
    padding: 0.5rem !important;
  }

  .m-pb-20 {
    padding-bottom: 2rem;
  }
}

@media(min-width:768px) and (max-width:1023px) {
  .explore-grids {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media(min-width:768px) and (max-width:992px) {
  .explorefcpipublish {
    font-size: 13px;
  }
}

@media screen and (max-width:768px) {
  .pall-80 {
    padding: 1rem !important;
  }
}

@media screen and (max-width: 600px) {
  .p-4 {
    padding: 0.8rem;
  }

  .m-w {
    width: 70% !important;
  }

  .m-pt-8 {
    padding-top: 0.5rem;
  }

}

@media screen and (max-width: 480px) {
  .p-m12 {
    padding: 20px;
  }

  .gap-\[15rem\] {
    gap: 12rem;
  }


  /* .grid-cols-3 {
    grid-template-columns: repeat(auto-fill, minmax(320px, 1fr)) !important;
  } */


  .mt-000 {
    margin-top: 0px !important;
  }

}

@media screen and (max-width: 600px) {
  .text-m-17 {
    font-size: 15px !important;
  }

  .blog-readmore-btn {
    padding: 0 1rem 1rem;
  }
}

.blog-1 {
  justify-content: space-between;
}

.pr-44 {
  padding-right: 1.2rem;
}

.explore-section-img {
  position: absolute;
  top: 20px;
  width: 100%;
  height: 50px;
  padding: 0px 20px;
}

.explore-name {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.explore-name-btn {
  color: #222222;
  font-size: 14px;
  border-radius: 100px;
  background-color: #c5e5ff;
  padding: 7px 25px;
}

.blog-readmore-btn {
  padding: 2rem;
  padding-top: 0px;
}

.mx-178 {
  width: 100%;
  max-width: 180px;
}

.blog-title { min-height: 120px;}

@media(min-width:994px) and (max-width:1024px) {
  .explore-name-btn {
    font-size: 13px;
  }
}

@media(min-width:768px) and (max-width:1024px) {
  .explorefcpi {
    font-size: 18px;
  }
}



@media(min-width:768px) and (max-width:993px) {
  .explore-name-btn {
    font-size: 12px;
  }
}

@media screen and (max-width: 768px) {
.blog-title { min-height: auto;}
}