.pl-50 {
    padding-left: 50px;
    padding-top: 20px;
    padding-bottom: 20px;
}
.-bottom-\[17rem\] {
    bottom: -10.5rem;
}
.pt-12 {
    padding-top: 0;
}
.bg-line {
    position: absolute;
    z-index: 0;
    top: -20%;
    right: -30%;
}
.bg-line {
    width: 100%;
    height: 100%;
}
.blogimg {
    position: relative;
    z-index: 2;
}
.blogimg img {
    width: 100%;
    height: auto;
}
.pl-30 {
    padding-left: 100px;
    padding-top: 100px;
}

.pt-100{ padding-top:60px;}

.img-arrow { width: auto;}

.mt-200 { margin-top: 30px;}

@media(min-width:1280px) and (max-width:1399px) {
    .recentbloghead {
        font-size: 39px;
    }
}

@media(min-width:1024px) and (max-width:1279px) {
    .recentbloghead {
        font-size: 36px;
    }
    .pl-30 {
        padding-left: 70px;
        padding-top: 40px;
    }
}
@media(min-width:993px) and (max-width:1023px) {
    .pl-30 {
        padding-left: 50px;
        padding-top: 0px;
    }
    .recentbloghead {
        font-size: 25px;
        line-height: 40px;
    }
}
@media(min-width:768px) and (max-width:992px) {
    .pl-30 {
        padding-left: 50px;
        padding-top: 90px;
    }
    .recentbloghead {
        font-size: 25px;
        /* line-height: 40px; */
    }
    
    .leading-\[51px\] {
        line-height: 40px !important;
    }
    .blogcont {
        height: 330px;
    }
  
}

@media (max-width: 992px) {

    .pt-28 {
        padding-top: 0rem;
    }

    /* .ptb-30 {
        padding-bottom: 40px;
    } */

    .pt-24 {

        padding: 1.5rem;
        padding-top: 2rem;
    }

    .brb-30 {
        border-radius: 15px;
    }

    .p-all-30 {
        padding: 15px;
    }

    .pt-20 {
        padding-top: 1rem;
    }

    .text-\[29px\] {
        font-size: 20px;
    }

    .text-\[17px\] {
        font-size: 14px;
    }

    .text-\[42px\] {
        font-size: 34px;
    }

    .leading-\[51px\] {
        line-height: 44px;
    }

    .pl-30 {
        padding-left: 50px;
    }

    .pr-\[20rem\] {
        padding-right: 2rem;
    }

    .pl-\[20rem\] {
        padding-left: 2rem;
    }

    /* .grid-cols-3 {
        grid-template-columns: repeat(auto-fill, minmax(340px, 1fr)) !important;
    } */

    .blogimg {
        width: 100%;
    }

}

@media (max-width: 768px) {
    .mob-block {
        display: block !important;
    }

    .pl-30 {
        padding-left: 30px;
        padding-top: 30px;
    }
    .pt-4 {
        padding-top: 0rem;
      }
      .recentbloghead {
        font-size: 25px;
        padding-bottom: 20px;
        padding-right: 10px;
      }
      .leading-\[51px\] {
        line-height: 30px;
      }

    .pr-\[20rem\] {
        padding-right: 1rem;
    }

    .pl-\[20rem\] {
        padding-left: 1rem;
    }

    .mob-block {
        display: block !important;
    }

    .p-24 {
        padding: 2rem;
    }

    .text-\[36px\] {
        font-size: 26px;
        padding-top: 20px;
    }

}

@media(width:768px) {
    .pl-30 {
        padding-left: 50px;
        padding-top: 90px;
    }
}
/* @media (max-width: 480px) {
.grid-cols-3 {
    grid-template-columns: repeat(auto-fill, minmax(320px, 1fr)) !important;
}
} */


.blog-smalltitle { font-size: 20px; color:#00549a; padding-top: 10px; font-weight: 500;}
.blog-left{ width:30%;}
.blog-right { width: 70%;}
.blogautor-img { margin-top: 20px; border: 1px solid #ececec; padding: 4px; border-radius: 5px; max-width: 200px;}

.blog-details { background-color: #fff; width: 100%; max-width: 1100px; margin: auto;}

.m-200{ margin-top: 40px;}
.blog-mainimg { width: 100%; max-width: 100%; height: auto; margin-top: 20px;}

@media (max-width:992px) { 

.blog-left{ width:100%;}
.blog-right { width: 100%;}

.blog-details { display: block !important;}


}

.blog-content a {
    color: #00549a;
    text-decoration: underline;  
}
.blog-content ul {
    list-style-type: disc; 
    margin-left: 20px; 
}

.blog-content ol {
    list-style-type: decimal; 
    margin-left: 20px;
}

.b-p-l-12{ padding-left: 1.2rem; padding-right: 1.2rem;}
.b-all-12{padding: 1rem;}
.bl-pt-8{ padding-top: 1rem;}
.blo-pt-60 { padding-top: 60px; padding-bottom: 60px;}

@media (max-width:992px) { 
    .blo-pt-60 { padding-top: 30px; padding-bottom: 30px;}
    .mb-gap-12 { gap: 1rem;}

}

@media(max-width:500px) {
    .bg-line {
        top: -10%;
    }
}

@media(max-width:430px) {
    .bg-line {
        top: -40px;
    }
}
