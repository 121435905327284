.dedicatedteamhead {
    position: relative;
    z-index: 1;
}

@media(min-width:600px) and (max-width:767px) {
    .illustrate-img {
        top: 4rem;
    }
}

@media(min-width:500px) and (max-width:599px) {
    .illustrate-img {
        top: 4rem;
    }
}

@media(min-width:400px) and (max-width:499px) {
    .illustrate-img {
        top: 7rem;
    }
}

@media(min-width:362px) and (max-width:399px) {
    .illustrate-img {
        top: 8rem;
    }
}

@media(min-width:300px) and (max-width:361px) {
    .illustrate-img {
        top: 11rem;
    }
}