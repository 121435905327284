@media print {
    .certificate-wrapper {
        display: block;
        margin: 20px;
        padding: 20px;
        border: 1px solid #ccc;
    }

    .certificate-details {
        margin-top: 20px;
    }
}
@media print {
    /* Add print-specific styles here */
    .print-button {
        display: none;  
    }
}
@media screen and (max-width:1279px) {
.m-cer-block-m{ display: block !important;}
.m-mt-100{ margin-top:20px ;}
}
