/* Remove black border color and update to gray */
[type='text'], 
[type='email'], 
[type='url'], 
[type='password'], 
[type='number'], 
[type='date'], 
[type='datetime-local'], 
[type='month'], 
[type='search'], 
[type='tel'], 
[type='time'], 
[type='week'], 
[multiple], 
textarea, 
select {
    appearance: none;
    background-color: transparent;
    border-color: #CBCBCB !important;  
    border-width: 1px;  
    border-style: solid;  
}
[type='email']:focus, 
[type='text']:focus, 
[type='password']:focus, 
textarea:focus, 
select:focus {
    appearance: none;
    background-color: transparent;
    border-color:#00549A !important;  
    border-width: 1px;  
    border-style: solid;  
}

.px-padding {
    padding-left: 1.5rem;
    padding-right: 1rem;
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;
}

.px-padding1 {
  padding-left: 1.5rem;
  padding-right: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.px-padding2 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
}


 
.bg-\[\#F4F4F4\] {
    background-color: transparent;
}

.p-texr-r { padding-right: 77px; color:#fff; padding-top: 1rem; font-size: 17px; }

@media screen and (max-width: 992px) {
  .p-texr-r { padding-right: 10px;font-size: 16px; }
.mob-padding{ padding: 1rem;}
.mob-padding-1{ padding: 1.5rem;}
.px-4 {
    padding-left: 1rem;;
    padding-right: 1rem;;
  }
}
.captcha-container {
    position: relative;
    overflow: hidden;
  }
  
  .captcha-container::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 100 100"><rect width="100" height="100" fill="white"/><rect x="5" y="5" width="5" height="5" fill="black" opacity="0.1"/><rect x="10" y="10" width="5" height="5" fill="black" opacity="0.1"/><rect x="15" y="15" width="5" height="5" fill="black" opacity="0.1"/><rect x="20" y="20" width="5" height="5" fill="black" opacity="0.1"/><rect x="25" y="25" width="5" height="5" fill="black" opacity="0.1"/><rect x="30" y="30" width="5" height="5" fill="black" opacity="0.1"/><rect x="35" y="35" width="5" height="5" fill="black" opacity="0.1"/><rect x="40" y="40" width="5" height="5" fill="black" opacity="0.1"/><rect x="45" y="45" width="5" height="5" fill="black" opacity="0.1"/><rect x="50" y="50" width="5" height="5" fill="black" opacity="0.1"/><rect x="55" y="55 width="5" height="5" fill="black" opacity="0.1"/></svg>');
    pointer-events: none;
  }

  .w-50 { width: 50%;}

  .bg-conbtn { background-color: #1AB78D; width: 150px; margin:auto; text-align: center;}
  .bg-conbtn:hover { background-color: #00549A; width: 150px; margin:auto; text-align: center;}
  .pt-6-lg{margin-top: 20px;}
  @media screen and (max-width: 768px) {
  .pt-6-md {margin-top: 20px;}
  }
  

  @media(max-width:768px) {
    .email-text {
      padding-top: 1.5rem;
    }
    .socialmedia-icons {
      padding-top: 2rem;
    }
    .contact-form {
      padding-top: 1rem;
    }
  }


  @media(max-width:540px) {
    .contact-submit-btn {
      font-size: 14px;
    }
  }