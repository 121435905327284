/* @media screen and (min-device-width: 992px) and (max-device-width: 1199px) {

    .flex-col {
        flex-direction: inherit !important;
    }
} */

.lg\:pt-28 {
    padding-right: 2rem;
}

@media (min-width: 992px) {
    .dis-inblock {
        display: inline-block;
    }

}

@media (max-width: 1199px) {
    .p-abouttext {
        padding: 20px 10px;
    }
}

@media screen and (max-width: 992px) {
    .p-8 {
        padding: 1.5rem;
    }

    .p-8 {
        padding: 0rem;
    }

    .md-bg-white {
        background-color: #fff;
        border-radius: 15px !important;
        padding: 20px;
    }

    .p-6 {
        padding: 0;
        padding-bottom: 10px;
    }

    .md-p-30 {
        padding-bottom: 10px;
    }

    .rounded-lg {
        border-radius: 15px !important;
    }

    .pt-36 {
        padding-top: 5rem;
    }

    .p-abouttext {
        padding: 20px 20px;
    }

    .hidden-md {
        overflow: hidden;
    }

    .px-4 {
        padding-left: 0;
        padding-right: 0;
    }

    .d-md-none {
        display: none;
    }

    .text-left {
        text-align: left;
    }

    .text-center-mob {
        text-align: center;
        margin: auto;
    }

    .formimg img {
        width: 150px;
    }

    .lg\:pt-28 {
        padding-right: 1rem;
        padding-left: 1rem;
    }

    .plr-15 {
        padding-left: 15px;
        padding-right: 15px;
    }

}

@media (min-width: 768px) {
    .md\:p-16 {
        padding: 2rem;
    }
}

.p-abouttext {
    padding: 70px 80px;
}

.visiontext-padding {
    padding-right: 50px;
}

@media(max-width:1023px) {
    .visiontext-padding {
        padding-top: 2rem !important;
    }
}

@media(max-width:767px) {
    .visiontext-padding {
        padding-top: 1rem !important; 
    }
}
@media(max-width:600px) {
    .visiontext-padding {
        padding-top: 0.5rem !important; 
    }
}

.missiontext-padding {
    padding-right: 50px;
}

@media (min-width: 1440px) {
.p-abouttext {
    padding: 70px 100px;
}

.visiontext-padding {
    padding-right: 110px;
}

.missiontext-padding {
    padding-right: 110px;
}
}


@media (width: 1024px) {
.vision-mission-padding {
    padding: 2rem 3rem  !important;
  }
  .about-text {
    font-size: 17px !important;
    padding-top: 20px !important;
    line-height: 26px !important;
  }
  .mob-hide-about-img {
    width: 51%;
  }
  .p-abouttext {
    padding: 40px 55px !important;
  }
}




@media (max-width: 1279px) {

    .visiontext-padding {
        padding-right: 0px;
    }

    .missiontext-padding {
        padding-right: 0px;
    }

}

@media (max-width: 1299px) {
    .p-abouttext {
        padding: 40px 55px;
    }
}

@media (max-width: 1024px) {
    .mflex {
        display: block;
    }

    .mt-20 {
        margin-bottom: 20px;
    }

    .mt-20 {
        margin-top: 0rem;
    }

    .mob-paddingbottom {
        padding-bottom: 20px;
    }

    .mob-mar-bt-20 {
        padding-bottom: 20px;
    }
}

.line-img {
    z-index: 20;
    position: absolute;
    right: -2.25rem;
    bottom: -10.5rem;
}

.aboutpadding {
    padding: 1rem;
    padding-bottom: 4rem;
}

.boardimg {
    position: relative;
    z-index: 1;
}

.boarticon {
    position: absolute;
    z-index: 0;
    bottom: -70px;
    left: -120px;
}

.p-lgg-2 {
    padding-left: 210px;
}

@media screen and (min-device-width: 1024px) and (max-device-width: 1279px) {
    .p-lgg-2 {
        padding-left: 150px;
    }

    .line-about {
        width: 80px;
    }

    .line-img {
        z-index: 20;
        position: absolute;
        right: -0.25rem;
        bottom: -4rem;
    }
}

.forum-img {
    width: 100%;
}

.p-formdiv {
    padding: 1.5rem;
    padding-right: 5rem;
    min-height: 340px;
}

.about-text {
    font-size: 19px;
    line-height: 32px;
}

.membertext {
    font-size: 21px;
}

@media(min-width:1025px) and (max-width:1279px){
    .about-text {
        font-size: 17px;
        line-height: 26px;
        padding-top: 1.5rem;
    }
}

@media screen and (min-device-width: 640px) and (max-device-width: 992px) {
    .membertext {
        font-size: 18px;
    }
}

@media screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    .mob-32-0 {
        padding-top: 2rem;
    }

    .m-pt-14 {
        padding-top: 0rem;
    }

    .pall-20 {
        padding: 20px 20px;
    }
}

@media (max-width: 1024px) {
    .about-text-padding {
        padding-right: 0px !important;
    }
}

@media (max-width: 992px) {
    .pall-20 {
        padding: 20px 20px;
    }

    .pt-m-14 {
        padding-top: 1rem !important;
    }

    .pt-m-0 {
        padding-top: 0px !important;
    }

    .pt-m-0 {
        padding-top: 0px !important;
    }

    .about-boards {
        padding-top: 0px !important;
    }

    .footer-menu {
        padding-bottom: 0px !important;
    }
}




@media (max-width: 768px) {
    .p-abouttext {
        padding: 20px 0px;
    }

    .mp-5 {
        padding: 0 !important;
    }

    .mpt-4 {
        padding-top: 1.2rem !important;
    }

    .m-blok-div-img { display: block !important;}

}

@media (max-width: 1024px) {
    .mob-with-100 {
        width: 100% !important;
    }

    .lg-img-none {
        display: none;
    }
}

.about-boards {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
}

.width50 {
    width: 50%;
}

.about-text-padding {
    padding-right: 100px;
}

.mar-r-20 {
    margin-right: 20px;
}

.board-img img {
    width: 160px;
    height: 160px;
}

.p-all-200 { padding: 5rem;}
.align-center { align-items: center;}

@media (max-width: 1280px) {
.p-all-200 { padding: 3rem;}
}

@media (max-width: 1024px) {
    .p-all-200 { padding: 2rem;}
    }
    @media (max-width: 992px) {
    .m-gap-16 { gap: 2rem;}
    }

    @media (max-width: 640px) {
    .m-w-100 { width: 100%; max-width: 136px;}
    .m-blok-div { display: block;}
    .m-p-21 { padding-top: 15px;}
    .text-20 { font-size: 20px !important;}
    .pl-m-5 {padding-left:1.5rem !important;}
    }
    @media(max-width:1023px) {
    .mob-hide-about-img { display: none;}
    }
    @media (max-width: 1024px) {
    .mob-w-100-abouttext { width: 100%;}
    .p-abouttext {
        padding: 0px 0;
      }
      .p-b-m-15 {padding-bottom: 20px;}
      .p-10-mob {padding: 0px 0px; padding-bottom: 15px;}
      .mob-pt-4 {padding-top: 0px;}
      .mob-p-t-1 {padding-top: 1rem !important;}
    }
    
    @media (min-width: 1024px) {
    .mar-top-100{ margin-top: 100px;}
    }
    @media (max-width: 1025px) {
    .mar-top-100{ margin-top: 0px;}
    }

    .forum-btn { background-color: rgb(240, 250, 255);}
    @media (max-width: 1024px) {
    .mob-pat-pt-4{ padding-top: 1rem !important;}
    }

 