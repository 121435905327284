.forgetpaswd { width: 600px; margin: auto; }

.fr-section { border-radius: 20px;}

.fr-section h4 { font-size: 24px;}

.forget-input {border-radius: 10px; padding-left: 20px; padding-right: 20px; width: 100%;}
.button-bgblues {  background-color: #00549A; transition: all 0.4s ease-out 0s;}
.button-bgblues:hover { background-color: #1AB78D; transition: all 0.4s ease-out 0s;}
.p-lr-20 { padding-left: 20px; padding-right: 20px; border-radius: 10px;}

.otp-title { margin-bottom: 10px;}

@media screen and (max-width:1024px) {
    .mob-block-forgot{display: block !important;}
    .forgetpaswd { width: 100%; margin-top: 20px;}
}

@media screen and (max-width:640px) {
    .forgetpaswd { width: 100%; margin: auto; }
}


/* OPT FORGET CSS */

.input-field-otp {
    width: 100px;
    /* Adjust width as needed */
    border: 1px solid #D9D9D9;
    padding: 20px 8px;
    border-radius: 6px;
    text-align: center;
    /* Center align text */
    font-weight: bold;
    font-size: 20px;
  }
  
  /* Hide spinner buttons */
  .input-field-otp::-webkit-inner-spin-button,
  .input-field-otp::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  
  .input-field-otp[type='number'] {
    -moz-appearance: textfield;
    /* Firefox */
  }
  
  .otp-box {
    margin: auto;
    width: 700px;
    display: flex;
    align-items: center;
    vertical-align: middle;
    height: 100vh;
  }
  
  .otp-logo { width: 100%; max-width: 300px; margin: 30px auto;}
  @media screen and (max-width: 720px) {
  .otp-box { width: 100%; padding: 0 10px;}
  
  .input-field-otp {
    width: 70px;
    padding: 15px 8px;  
    font-size: 18px;
  }
  }
  
  @media screen and (max-width: 480px) {  
    .input-field-otp {
      width: 50px;
      padding: 12px 8px;  
      font-size: 16px;
    }
    .otp-logo { width: 100%; max-width: 220px; margin: 20px auto;}
    }

    @media(max-width:768px) {
      .forgot-pass-email-text, .email-inputs, .otp-btn {
        padding-top: 1rem;
      }
    }

    @media(max-width:640px) {
      .fr-section {
        margin-top: 1rem;
      }
    }

    @media(max-width:436px) {
      .forgot-pass-text {
        font-size: 28px;
      }
    }

    @media(max-width:380px) {
      .forgot-pass-text {
        font-size: 24px;
        line-height: 28px;
      }
    }

    @media(max-width:340px) {
      .forgot-pass-text {
        font-size: 20px;
        line-height: 24px;
      }
    }


