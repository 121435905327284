.round  {
    width : 45px;
    height: 45px;
   
    border-radius: 100%;
    background-color: #303031;
    padding: 12px;
}

@media(min-width:1024px) and (max-width:1280px) {
    .forumhead {
        font-size: 20px;
    }
}