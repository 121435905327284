@media(min-width:1025px) {
    .reset-pass-img {
        margin: 1.5rem;
    }
}

@media(width:1024px) {
    .reset-pass-img {
        margin: 15px;
        margin-top: 45px;
        width: 100%;

    }
}
@media(min-width:1536px) {
.reset-pass-text {
    top: 25rem;
}
.reset-pass-section {
    margin-top: 4rem;
}
}

@media(min-width:1400px) and (max-width:1535px) {
    .reset-pass-text {
        top: 22rem;
    }
    .reset-pass-section {
        margin-top: 2rem;
    }
}

@media(min-width:1280px) and (max-width:1399px) {
    .reset-pass-text {
        top: 20rem;
    }
    .reset-pass-section {
        margin-top: 1rem;
    }
}

@media(min-width:1025px) and (max-width:1279px) {
    .reset-pass-img {
        height: 39.4%;
    }
    .reset-pass-text {
        top: 18rem;
    }
}

@media(width:1024px) {
    .reset-pass-container {
        width: 50% !important;
    }
    .reset-pass-img {
        height: 38.5%;
    }
    .reset-pass-text {
        top: 19rem;
    }
}

@media(min-width:769px) and (max-width:1023px) {
    .reset-pass-main {
        display: flex;
        flex-direction: column;
    }
}

@media(max-width:768px) {
    .reset-pass-labels,  .reset-pass-btn {
        padding-top: 1rem;
    }
    .reset-pass-inputs {
        padding-top: 0.5rem;
    }
}

@media(max-width:436px) {
    .reset-pass-text {
        font-size: 28px;
        line-height: 30px;
    }
}
