.blogimg img {
    width: 100%;
    height: auto;
    width: 100%;
}

.blogsection {
    position: relative;
}

.blogslider {
    position: relative;
    display: block;
    float: left;
}

.lg-h-130 {
    height: 400px;
}

@media(min-width:1536px) {
    .latest-blog {
        padding-top: 20px;
    }
}

@media(min-width:1366px) and (max-width:1399px) {
    .latest-blog {
        padding-top: 18px;
    }
}

@media(width:1280px) {
    .latest-blog {
        padding-top: 20px;
    }
}


@media (max-width: 992px) {
    .lg-h-130 {
        height: 350px;
    }
}

@media (max-width: 768px) {
    .lg-h-130 {
        height: 300px;
    }
}

@media (max-width:640px) {
    .lg-h-130 {
        height: 270px;
    }
}

@media (max-width:480px) {
    .lg-h-130 {
        height: 220px;
    }
}

@media screen and (min-device-width: 1535px) and (max-device-width: 1600px) {


    .lg-h-130 {
        height: 500px;
    }
}

@media screen and (min-device-width: 1440px) and (max-device-width: 1535px) {


    .lg-h-130 {
        height: 434px;
    }
}

@media screen and (min-device-width: 1366px) and (max-device-width: 1440px) {


    .lg-h-130 {
        height: 430px;
    }
}

@media screen and (min-device-width: 1024px) and (max-device-width: 1366px) {


    .lg-h-130 {
        height: 430px;
    }
}



@media screen and (min-device-width: 992px) and (max-device-width: 1199px) {
    .blogimg {
        display: block;
    }

    .lg-h-130 {
        height: 400px;
    }
}

.ptop-20 {
    padding-top: 20px;
}

.pt-ntop {
    padding-top: 2rem;
}

.pp-6 {
    padding: 1.4rem;
}

.pad-6 {
    padding: 0.5rem;
}
@media (max-width:565px) {
.pad-6-mob{padding: 0;}
.pall-12 {padding: 10px !important;}
}

@media (max-width:992px) {
    .pp-6 {
        padding: 0.5rem;
    }
}

.mobile-flex button {
    margin-left: 10px;
}

.evventcalnderdiv {
    display: flex;
}

.width65 {
    width: 60%;
}

.width35 {
    width: 40%;
}

@media (max-width:498px) {
    .mobile-flex {
        display: block;
    }

    .mobile-flex .btn-2 {
        display: block;
    }
}

@media (max-width:1024px) {
    .width65 {
        width: 100%;
    }

    .width35 {
        width: 100%;
    }

    .evventcalnderdiv {
        display: block;
    }
}

.ev-date {
    font-size: 18px;
}

/* .events-items-date {
    justify-content: space-between;
} */

.events-btn-pad {
    padding: 18px 24px;
}

@media (min-width:1536px) {
    .events-items-date .pxx-44 {
        padding-left: 1.34rem;
        padding-right: 1.3rem;
        margin-left: 5px;
        margin-right: 5px;
    }
}

@media (max-width:1535px) {
    .events-items-date .pxx-44 {
        padding-left: 1rem;
        padding-right: 1rem;
        margin-left: 2px;
        margin-right: 2px;
    }

    .ev-date {
        font-size: 17px;
    }
}

@media (max-width:1400px) {
    .events-items-date .pxx-44 {       
        margin-left: 1px;
        margin-right: 1px;
    }
}

@media screen and (min-device-width: 1280px) and (max-device-width: 1300px) {
    .ev-date {
        font-size: 14px;
    }

    .events-items-date .pxx-44 {
        padding-left: 1.2rem;
        padding-right: 1.2rem;
        margin-left: 3px;
        margin-right: 3px;
    }
}

.btn-17-text { font-size: 17px;}
.pading-6 {padding: 15px 15px;}

@media screen and (min-device-width: 1024px) and (max-device-width: 1280px) {
    .events-items-date .pxx-44 { width: 120px; margin: 3px 0px;}
    .ev-date {
        font-size: 15px;
      }

}

@media (max-width:1280px) {
.events-btn-pad { padding: 14px 10px;}
.btn-17-text { font-size: 14px;}
}
@media (max-width:1024px) {
.events-items-date {
    justify-content: start;
  }
  .events-items-date .pxx-44 { margin-left: 5px; margin-right: 5px;}
}

@media (max-width: 992px) {
    .events-items-date .pxx-44 {
      padding-left: 2rem;
      padding-right: 2rem;
    }
  }
  @media (max-width: 768px) {
  .ev-date {
    font-size: 15px;
  }
}
.forum-name-head { font-size: 36px; font-weight: bold; text-transform: uppercase; padding-top: 20px; }
  @media (max-width: 640px) {
    .events-items-date .pxx-44 {
      padding-left: 1rem;
      padding-right: 1rem;
    }
    .forum-name-head { font-size: 20px; line-height: 26px; font-weight: bold; text-transform: uppercase; padding-top: 30px; padding-bottom: 40px; }
  }

  @media (max-width: 520px) {
    .events-items-date .pxx-44 {
      padding-left: 1.5rem;
      padding-right: 1.5rem;
    }

    .ev-date {
        font-size: 15px;
      }
      .evn-item button {  margin-bottom: 10px; width: 125px;}
      
  }

  @media (max-width: 360px) {    
      .evn-item button {  margin-bottom: 10px; width: 110px;}
      .ev-date {
        font-size: 14px;
      }
      .events-items-date .pxx-44 {
        padding-left: 1rem;
        padding-right: 1rem;
      }      
  }

  @media (max-width: 320px) {    
    .evn-item button {  margin-bottom: 10px; width: 100%;}
    .ev-date {
      font-size: 14px;
    }
    .events-items-date .pxx-44 {
      padding-left: 1rem;
      padding-right: 1rem;
    }  
    .env-block { display: block;}    
}
@media (max-width: 768px) { 
.blog-m .pt-10{ padding-top: 20px;}
.blog-m .pl-8 {padding-bottom: 10px;}
}

@media(max-width:550px) {
    .fcpi-event-slider {
        padding: 15px 20px 10px 20px;
    }
}

@media(max-width:380px) {
    .fcpi-event-slider {
        padding: 15px 20px 10px 20px;
    }
}

