.podcast-link { margin-top: 15px;}
.podcast-link p { font-size: 16px;}
.podcast-link span { cursor: pointer; font-weight: bold; color: rgb(35, 56, 118)}

.podcast-link span:hover { cursor: pointer; font-weight: bold; color: rgb(26, 183, 141)}

.gap-com {
    gap: 1.2rem;
    padding-bottom: 20px;
  }

  .w-35{ width: 40px;}
  .podcast-text { font-size: 21px; font-weight: 500;}
  .p-p-all { padding: 4rem 2rem; padding-bottom: 0;}

  .font-40 { font-size: 40px;}
.host-img { width: 7rem; height: 7rem;}

  @media screen and (max-width: 1024px) {
  .font-40 { font-size: 30px;}
  }
  @media screen and (max-width: 768px) {
    .mobile-pod-block-m{ display: block !important; margin-bottom: 10px; width: 100%;}
    .mt-m-2-20 { margin-top: 15px;}
    .m-pt-6-0 { padding-top: 0px;}
    .m-pt-6-10 { padding-top: 10px;}

    .m-p6-10 { padding: 1rem 0px;}
  }

  @media screen and (max-width: 640px) {
  .podcast-text { font-size: 18px;}
  .w-35{ width: 35px;}
  .font-40 { font-size: 26px;}
  }

  @media(max-width:768px) {
    .podcastname {
      padding-top: 1rem;
    }
  }

