.rounded-lg {
    border-radius: 30px !important;
}

element.style {
    aspect-ratio: 38 / 9;
}

.bg-white\/30 {
    background-color: #76a6e14d;
}

.px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 1rem;
}

.hometext {
    padding-top: 2rem;
    padding-bottom: 1rem;
}

.towards {
    border-radius: 30px;
    padding: 1.8rem 2rem;
    background-color: #fff;
}

.home-img {
    height: 504px;
}

.hightcurser {
    height: 180px;
}

.event-img {
    width: 428px;
    margin-left: auto;
    margin-right: 2rem;
    height: 334px;
    border-radius: 23px;
    margin-top: 9rem;
    
}

@media(min-width:1536px) {
    .towards {
        margin-bottom: 3px;
    }
}



@media(min-width:1400px) and (max-width:1535px) {
    .towards {
        margin-bottom: 16px;
    }
}
@media(min-width:1280px) and (max-width:1400px) {
    .towards {
        margin-bottom: 19px;
    }
}
@media(min-width:1200px) and (max-width:1280px) {
    .towards {
        margin-bottom: 5px;
    }
}
@media(min-width:1024px) and (max-width:1200px) {
    .towards {
        margin-bottom: 15px;
    }
}




@media screen and (max-width: 768px) {
    .towards {
        margin-bottom: 0px;
    }

    .hometext {
        padding-top: 1.2rem;
        padding-bottom: 0rem;
    }

    .hide-mob {
        display: none;
    }

    .event-img {
        width: 100%;        
        height: auto;
        border-radius: 23px;
        margin-top: 3rem;
        margin-bottom: 2rem;
    }

    .home-img {
        height: auto;
        padding-bottom: 1rem;
        padding-left: 20px; padding-right: 20px;
    }

    .hightcurser {
        height: 90px;
      }
      .mmbt-tb-0 { margin-top: 1rem; margin-bottom: 0;}
}
@media only screen and (min-device-width: 1280px) and (max-device-width: 1536px) and (orientation:portrait) {
    
}
@media screen and (max-width:480px) {
.mob-width-100{ width: 100%;}
.mob-text-12 { font-size: 12px;}
.p-1-m {padding: 0.5rem;}
.round-m-30 { border-radius: 18px !important;}
.mob-center-cal{ align-items: center;}
.pt-pb-0 { padding-top: 0px; padding-bottom: 0px;}
}