.pr-240 {
  padding-right: 6rem;
}

.pt-200 {
  padding-top: 8rem;
}

@media (min-width:1280px) and (max-width:1536px) {
  .speakers-collaborated {
    width: 200px;
  }
}

@media(min-width:1300px) and (max-width:1399px) {
  .fcpi-count-head {
    font-size: 17px !important;
  }
}

@media (min-width:993px) and (max-width:1024px) {
  .speakers-collaborated {
    width: 200px;
  }
}

@media screen and (max-width: 992px) {
  .responsive-card {
    padding-top: 10px;
  }

  .pt-200 {
    padding-top: 3rem;
  }

  .pr-40 {
    padding-right: 7rem;
  }

  .pr-240 {
    padding-right: 3rem;
  }
}



@media screen and (max-width: 768px) {
  .responsive-card {
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding-top: 10px;
  }

  .pr-m40 {
    padding-right: 5rem !important;
    padding-left: 30px;
    text-align: left;
  }

  .pl-m30 {
    padding-left: 30px;
  }

  .pr-m24 {
    padding-right: 2rem !important;
    padding-bottom: 2rem;
    text-align: left;
  }

  .textbalck {
    color: #000 !important;
  }
}

.fcpi-counter {
  font-size: 18px;
}

.fcpi-counternum {
  font-size: 36px;
}

.count-item {
  position: relative;
  display: flex;
}

.counter-div {
  display: block;
  text-align: left;
  padding-right: 20%;
}

.count-item::after {
  content: "";
  display: block;
  width: 2px;
  background-color: #f3f3f3;
  height: 40px;
  border-radius: 10px;
  position: relative;
  top: 10px;
}



@media screen and (max-width: 1299px) {
  .fcpi-counter {
    font-size: 16px;
  }

  .fcpi-counternum {
    font-size: 30px;
  }
}

@media screen and (max-width: 1279px) {

  .counter-block {
    display: block !important;
  }

  .mob-full-width {
    width: 100%;
  }

  .counter-div {
    display: block;
    text-align: left;
    padding-right: 30%;
  }

  .fcpi-counter {
    font-size: 20px;
  }

  .fcpi-counternum {
    font-size: 40px;
  }
  
  .mob-mt-6 { margin-top: 20px;}
}

.p-counter{ padding: 4rem;}
.pt-counter{ padding-top:4rem;}


@media(max-width:640px) {
  .count-item::after { display: none;}
}
@media screen and (max-width: 992px) {

  

  .fcpi-counter {
    font-size: 16px;
  }

  .fcpi-counternum {
    font-size: 24px;
  }
  .p-counter{ padding: 2rem;}
  .pt-counter{ padding-top:0rem;}
  /* .p-counter-item{ padding-top: 1rem;} */

  .top-50 {
    top: 23rem;
  }

}

@media(min-width:768px) and (max-width:992px) {
  .design-image {
    top: 155px;
    width: 50%;
  }
}

@media(min-width:640px) and (max-width:767px) {
  .design-image {
    top: 155px;
    width: 50%;
  }
}

@media(min-width:601px)and (max-width:639px) {
  .design-image {
    top: 250px;
    width: 60%;
  }
}

@media(max-width:600px) {
  .design-image {
    top: 230px;
    width: 60%;
  }
}

@media(max-width:480px) {
  .design-image {
    top: 83% !important;
    width: 60%;
  }
}



@media screen and (max-width: 640px) {
.fcpi-counter {
  font-size: 14px;
}
.fcpi-counternum {
  font-size: 30px;
}
.counter-div { 
  padding-right: 0;
}

.p-counter-item {
  padding-top: 0rem;
}
}

@media screen and (max-width: 480px) {
  .top-50 {
    top: 26rem;
  }
}

.mtop-m-10 { margin-top: 40px;}

@media(max-width:379px) {
  .speakers-collaborated {
    width: 140px;
  }
}