.download-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.downsearch {
    justify-content: end;
}

.download-title {
    width: 60%;
}

.download-search {
    width: 40%;
}

.download-title h3 {
    font-size: 24px;
    font-weight: 600;
    color: #222222;
}

.searchform-down {
    width: 100%;
}

.download-main {
    padding-top: 20px;
   
}

.download-item {
    border-top: 1px solid #D9D9D9;
    padding: 20px 10px;
    display: flex;
}

.download-item p {
    color: #222222;
    font-size: 20px;
    font-weight: 500;
}

.download-icon {
    padding-left: 30px;
    display: flex;
    gap: 0.5rem;
    text-align: center;
    align-items: center;
}

.d-icon {
    background-color: #EDF7FF;
    width: 36px;
    height: 36px;
    text-align: center;
    line-height: 36px;
    border-radius: 3px;
    display: block ruby;
}

.download-name{ width: 75%;}

.pdf-icon-dwonload { width: 50px; height: 50px; border-radius: 10px; padding: 0.5rem; }
.pdf-icon-dwonload:hover{ background-color: #EDF7FF;}

.passwrd-box{ width: 540px;}
.passwrd-box input { width: 100%;}
.passwrd-box button { width: 100%;}

@media screen and (max-width:640px) {
.passwrd-box{ width: 100%;}
}

@media screen and (max-width:1023.5px) {
.download-item { display: block !important; padding: 0;}
.download-item .pl-8 { padding-left: 0px;}
.download-name { width: 100%;}
}

