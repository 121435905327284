@media(min-width:1401px) and (max-width:1536px) {
  .footer-socail{
    padding-left: 5.5rem !important;
  }
}
@media screen and (min-width: 1600px) {
  .footer-gap {
    padding-left: 60px;
  }

  .footer-socail {
    padding-left: 8rem;
  }
}

@media only screen and (min-device-width: 1400px) and (max-device-width: 1600px) and (orientation:portrait) {

  .footer-gap {
    padding-left: 40px;
  }

  .footer-socail {
    padding-left: 3rem !important;
  }
}

@media screen and (max-width: 1400px) {

  .footer-gap {
    padding-left: 30px;
  }

  .footer-socail {
    padding-left: 2rem !important;
  }

  .newsletter {
    font-size: 16px !important;
    outline: none !important;
  }


}

.newsletter :focus-visible{
  outline: none !important;
}




@media screen and (min-width: 1024px) {
  .footertop-padding {
    padding-top: 4rem;
  }

  .getbtn {
    font-size: 14px !important;
    width: 170px;
  }
}

.join-btn {
  margin-top: 20px;
  max-width: 210px;
}

.footer-socail {
  padding-top: 4rem;
  padding-left: 8rem;
}

.footer-menu {
  padding-top: 4rem;
  padding-bottom: 30px;
}

.getbtn {
  margin-top: 0;
  border-radius: 105px;
  background-color: #00549a;
  padding: 13px 24px;
  color: #fff;
  font-size: 16px;
  width: 170px;
}

.newsletter {
  display: flex;
  align-items: center;
}

.location {
  width: 45px;
  height: 45px;
  border-radius: 100%;
  background-color: #303031;
  padding: 12px;
}

@media(min-width:1280px) and (max-width:1399px) {
  .location-icon {
    margin-bottom: auto;
    margin-top: 1.7rem;
  }
}

@media(min-width:1024px) and (max-width:1279px) {
  .location-icon {
    width: 54px;
    margin-bottom: auto;
    margin-top: 1.7rem;
  }
  .mails-tab {
    margin-bottom: 15px;
  }
}

.text {
  width: 249px;
}

.location_footer {
  width: 38px;
  height: 38px;

  border-radius: 100%;
  background-color: #303031;
  padding: 12px;
}

.grid-cols-custom {
  display: grid;
  grid-template-columns: auto 1.5fr 0.5fr 1fr;
  gap: 0.5rem;
  justify-content: space-between;
}

@media screen and (min-device-width: 1200px) and (max-device-width: 1366px) {

  /* .newsletter {
    display: block;
    align-items: center;
  } */

  .getbtn {
    margin-left: 0;
  }

  .pl-1200 {
    padding-left: 2rem;
  }

  .pr-16 {
    padding-right: 3rem;
  }

  .right-28 {
    right: 3rem;
  }
}

@media screen and (min-device-width: 1024px) and (max-device-width: 1999px) {

  .lg\:grid-cols-\[repeat\(auto-fill\,minmax\(340px\,1fr\)\)\] {
    grid-template-columns: repeat(auto-fill, minmax(340px, 1fr));
  }
}

@media(min-width:1280px) and (max-width:1400px) {
  .icon-items {
    justify-content: start !important;
  }
}

@media screen and (max-width: 1280px) {
  .grid-cols-custom {
    grid-template-columns: 1fr 1fr;
  }

  .icon-items {
    justify-content: start !important;
  }
}

@media(width:1024px) {
  .footer-menu{
    padding-left: 30px !important;
    padding-top: 4rem !important;
  }
  .footer-socail{
    padding-top: 4rem !important;
  }
}

@media screen and (max-width: 1024px) {
  .grid-cols-custom {
    grid-template-columns: 1fr;
  }

  .footer-gap {
    padding-left: 0px;
  }

  .mob-gap-padding {
    margin-top: 20px;
    border-top: 1px solid #58585A;
    padding-top: 20px;
  }

  .footer-menu {
    padding-top: 0rem;
  }

  .footer-socail {
    padding-top: 1rem;
    padding-left: 0rem !important;
    padding-bottom: 30px;
  }
}

@media(min-width:993px) and (max-width:1023px) {
  .mob-gap {
    margin-top: 62px !important;
  }
}

@media(min-width:768px) and (max-width:992px) {
  .mob-gap {
    margin-top: 62px !important;
  }
}


@media screen and (max-width: 1024px) {


  .right-28 {
    right: 0rem;
  }

  .p-mob {
    padding: 1.5rem;
  }

  /* .border-mob {
    border: 2px solid #58585A
  } */

  .pt-32 {
    padding-top: 1rem;
  }

  .pl-24 {
    padding-left: 1.5rem;
  }

  .mb-2m {
    margin-bottom: 20px;
  }

  .maxm-228 {
    max-width: 228px;
  }

  /* .footer-socail {
    padding-top: 0rem;
    padding-left: 1.3rem;
    padding-bottom: 1.3rem;
  } */


}

@media(max-width:640px) {
  .footer-menus {
    margin-top: 0.75rem;
  }
}

.footer-socail {
  display: block;
  justify-content: end;
}

.icon-items {
  /* justify-content: end; */
  margin-top: 15px;
}

.social-head p {
  text-align: left;
}

.social-head,
.social-icons {
  display: block;
  width: 100%;
}

.social-icons p{ font-size: 16px;}

.location_footer:hover {
  background-color: #00549a;
}

[type='text'],
[type='email'],
[type='url'],
[type='password'],
[type='number'],
[type='date'],
[type='datetime-local'],
[type='month'],
[type='search'],
[type='tel'],
[type='time'],
[type='week'],
[multiple],
textarea,
select {
  appearance: none;
  /* background-color: #fff; */
  border-color: #222222;
}

.footerbottom {
  border-top: 1px solid #444;
  width: 100%;
  text-align: center;
  padding: 30px 0px;
  padding-bottom: 0;
}

@media screen and (max-width:370px) {
  .px-\[24px\] {
    padding-left: 20px;
    padding-right: 20px;
  }

  .m-width {
    width: 175px;
  }

  .px-\[32px\] {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.p-footer-all {
  padding: 40px;
  margin-top: 20px;
}

.hoztoz-color {
  color: rgb(128, 128, 128);
}

.newsletter {
  border-radius: 105px;
  background-color: #303031;
  padding-left: 15px;
  color: #969696;
  width: 98%;
  font-size: 17px;
}

@media screen and (max-width:540px) {
  .m-text-16 {
    font-size: 15px;
  }

  .p-mob {
    padding: 1rem 1.5rem;
  }

  .hide-mobile-div { display: none;}
}

@media screen and (max-width:480px) {
  .newsletter {
    border-radius: 105px;
    background-color: #303031;
    padding-left: 10px;
    color: #969696;
    width: 100%;
    font-size: 14px !important;
  }

  .getbtn {
    font-size: 13px;
    width: 165px;
  }

  .p-footer-all {
    padding: 20px;
  }
  .footer-menu {
    padding-top: 0rem;
  }

  .footer-menu {  
    padding-bottom: 0px;
  }

  .footerbottom {   
    padding: 18px 0px;    
    padding-bottom: 7px;
  }
  .leading-\[27px\] {
    line-height: 26px;
  }
}

.gap-footer{ gap: 2rem;}

@media screen and (max-width:992px) {
  .gap-footer{ gap: 1.5rem;}
  .mob-gap { margin-top: 30px;}
  .mob-gap1 { margin-bottom: 20px;}
}

@media screen and (max-width:640px) {
  .gap-footer{ gap: 0rem;}
}


.mtop-20 {
  margin-top: 30px;
}

