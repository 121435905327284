.button {
    background-color: #fff;
 
    
}

.button:hover {
    background-color: #00549A !important;
}

.button:hover .button-text {
    color: #fff;  
}

.dashboard-image {
    filter: grayscale(100%);
    transition: filter 0.3s;
}

.button:hover .dashboard-image {
    filter: none;
}
.button {
    /* Common button styles */
    background-color: transparent;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.button:hover {
    /* Hover effect */
    background-color: #e0e0e0; /* Example background color on hover */
}
/* pppppppppppppppppppppppppppppppppppppp */
/* .button.active {
   
    background-color: #00549a;  
}

.dashboard-image {
   
    width: 24px;  
    height: 24px;  
    transition: filter 0.3s ease;  
}

.white-image {
    
    filter: brightness(0) invert(1); 
} */
 /* ppppppppppppppppppppppp */