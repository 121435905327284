.password-input-container {
    display: flex;
    flex-direction: column;
  }
  
  .input-wrapper {
    position: relative;
    margin-bottom: 20px;
  }
  
 
  .password-input {
    width: 100%;
    padding-right: 30px;
    border: 1px solid #ccc;
    border-radius: 5px;
    height: 40px;  
    line-height: 40px; 
    background-color: white; 
  }
  .email-input {
    width: 100%;
    padding-right: 30px;  
    border: 1px solid #ccc;
    border-radius: 5px;
    height: 40px;  
    line-height: 40px; 
    background-color: white; 
  }
  
  .password-toggle-icon {
    position: absolute;
    top: 50%;
    right: 5px;
    transform: translateY(-50%);
    width: 20px;  
    cursor: pointer;
  }
  
  .password-toggle-icon {
    z-index: 1;  
  }
 
 
input[type='password']  {
    background-color: white;  
}
input[type='email']  {
    background-color: white;  
}

input[type='text']  {
  background-color: white;  
}

.button-bgblues {  background-color: #00549A; transition: all 0.4s ease-out 0s;}
.button-bgblues:hover { background-color: #1AB78D; transition: all 0.4s ease-out 0s;}

@media screen and (max-width:992px) { 
.mob-block { display: block;}
.mob-mt-20 {  margin-top: 20px; }
}

@media(min-width:1024px) and (max-width:1535px) {
  .loginimg {
    height: 100%;
  }
}

@media(min-width:769px) and (max-width:1023px) {
  .login-page {
    display: flex;
    flex-direction: column;
  }
  .loginimg {
    height: 400px !important;
  }
}

@media(min-width:769px) and (max-width:992px) {
  .loginimg {
    margin-top: 20px;
  }
}

@media(max-width:768px) {
  .login-email, .login-password {
    padding-top: 1rem;
  }
}

@media(max-width:431px) {
  .login-page-text {
    font-size: 28px;
  }
}

@media(max-width:375px) {
  .login-page-text {
    font-size: 25px;
    line-height: 30px;
  }
}

@media(max-width:346px) {
  .login-page-text {
    font-size: 22px;
    line-height: 26px;
  }
}



