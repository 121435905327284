@media(min-width:993px) and (max-width:1023px) {
    .blogdetailhead {
        font-size: 34px;
    }
}

@media(min-width:768px) and (max-width:992px) {
    .blogdetailhead {
        padding-top: 15px;
    }
    .blogdetailhead {
        font-size: 30px;
    }
}

@media(max-width:767px) {
    .blogdetailhead {
        font-size: 20px;
        padding-bottom: 20px;
        line-height: 30px;
    }
}

