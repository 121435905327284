.bgm {
    margin-top: 60px;
  }

  .event-details {
    margin-top: 20px;
}

.event-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
}

.event-table th, .event-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}

.event-table th {
    background-color: #f2f2f2;
}
.form {
    border: 2px solid red;  /* To see the form boundaries */
    padding: 10px;
    background-color: #f0f0f0; /* Highlight form area */
}

.event-table td img {
    display: block;
    max-width: 100px;
    max-height: 100px;
    object-fit: cover;
}

.action-buttons {
    display: flex;
    gap: 8px;
}

.edit-button, .delete-button {
    padding: 8px 12px;
    background-color: #007bff;
    color: #fff;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.edit-button:hover, .delete-button:hover {
    background-color: #0056b3;
}


/* General Styles */
.bg-light {
    background-color: #f4f4f4;
}

.bg-white {
    background-color: #ffffff;
}

.border-gray {
    border-color: #d1d5db;
}

.rounded {
    border-radius: 8px;
}

.text-primary {
    color: #00549a;
}

.text-secondary {
    color: #58585a;
}

.text-error {
    color: #d9534f;
}

.p-6 {
    padding: 1.5rem;
}

.p-8 {
    padding: 2rem;
}

.pt-6 {
    padding-top: 1.5rem;
}

.pt-8 {
    padding-top: 2rem;
}

.pb-4 {
    padding-bottom: 1rem;
}

.flex {
    display: flex;
}

.gap-3 {
    gap: 0.75rem;
}

.gap-6 {
    gap: 1.5rem;
}

.justify-end {
    justify-content: flex-end;
}

.w-full {
    width: 100%;
}

.w-40 {
    width: 40%;
}

.text-18 {
    font-size: 18px;
}

.text-16 {
    font-size: 16px;
}

.font-semibold {
    font-weight: 600;
}

.font-medium {
    font-weight: 500;
}

.font-normal {
    font-weight: 400;
}

.leading-normal {
    line-height: normal;
}

.leading-24 {
    line-height: 1.5rem;
}

/* .border {
    border: 1px solid;
} */

.border-gray-400 {
    border-color: #d1d5db;
}

.bg-gray-100 {
    background-color: #f7fafc;
}

.bg-gray-500 {
    background-color: #6b7280;
}

.text-center {
    text-align: center;
}

.disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

/* Specific Styles */
.select-event {
    border-radius: 6px;
    padding: 0.5rem 1rem;
    background-color: #f4f4f4;
}

.file-input {
    border-radius: 6px;
    padding: 0.5rem 1rem;
    background-color: #f4f4f4;
}

.upload-button {
    background-color: #00549a;
    color: #ffffff;
    padding: 0.5rem 1rem;
    border-radius: 10px;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.upload-button:hover {
    background-color: #004080;
}

.exit-button {
    background-color: #6b7280;
    color: #ffffff;
    padding: 0.5rem 1rem;
    border-radius: 8px;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.exit-button:hover {
    background-color: #4b5563;
}
.form-input {
    width: 100%; /* Make the input take up the full width of its container */
   /* Optional: Limit the maximum width */
}

.h-btn{filter: brightness(0) invert(1); width: 30px;}

/* Example CSS that should not hide the form */
 
