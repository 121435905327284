.gradient::after {
  background: rgb(2, 0, 36);
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 41.9%, rgba(0, 0, 0, 0.69) 97.66%);


  position: absolute;
  content: '';
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  overflow: hidden;
}

.text-custom-color {
  color: rgba(68, 128, 178, 0.6);
}

@media screen and (max-width: 1024px) {
  .mob-hide {
    display: none;
  }

  .w-\[70\%\] {
    width: 100%;
  }

  .mm-0 {
    margin-top: 0px !important;
  }
}

@media screen and (max-width: 992px) {
  .mob-full {
    width: 100%;
  }

  .mob-block {
    display: block;
  }

  .mob-fullwidth {
    width: 100%;
  }

  .mmt-20 {
    margin-top: 20px;
  }

  .mmb-20 {
    padding-bottom: 20px;
  }
}

@media screen and (max-width: 768px) {

  .mob-center {
    text-align: center;
    justify-content: center;
    padding-bottom: 10px;
  }

  .mob-left {
    text-align: left;
  }

  .mm-0 {
    margin-top: 0px !important;
  }
}



@media screen and (max-width: 600px) {
  .gap-\[16rem\] {
    gap: 2rem;
  }

  .w-\[60\%\] {
    width: 80%;
  }

  .mobflex {
    display: block !important;
  }

  .mt-mob {
    margin-top: 15px;
  }

  .pt-4 {
    padding-top: 0.5rem;
  }

  .gap-10 {
    gap: 1.5rem;
  }

  .p-10 {
    padding: 0.5rem;
  }
}

.m-r-20 {
  padding-right: 20px;
  margin-right: 15px;
}

.speakbuton {
  float: right;
}

.event-bt-pt-3 {
  padding-top: 1rem;
}

@media screen and (max-width: 480px) {
  .event-block-mob {
    display: block !important;
  }
}

@media screen and (max-width: 370px) {
  .event-block-mob {
    display: block !important;
  }

  .event-bt-pt-3 {
    padding-top: 0rem;
  }
}

.search-btn {
  right: 0;
}

.searchbox {
  width: 60%;
}

.searchform {
  width: 100%;
  margin-bottom: 30px;
}

.search-button {
  padding-top: 14px;
  position: absolute;
  z-index: 1111;
  right: 24px;
  cursor: pointer;
}

.event-padding-12 {
  padding-left: 2rem;
  padding-right: 2rem;
}

@media screen and (max-width: 640px) {
  .searchbox {
    width: 100%;
  }

  .event-padding-12 {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

.tooltip {
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #333;
  color: #fff;
  padding: 0.5rem;
  border-radius: 0.25rem;
  white-space: nowrap;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.tooltip-visible {
  opacity: 1;
}

.smooth {
  transition: background-color 600ms ease;
}

.evenet-m-name {
  color: #fff;
  font-weight: bold;
  font-size: 34px;
  line-height: 42px;
}

.evenet-m-subname {
  color: #fff;
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  padding-left: 30px;
  margin-top: 5px;
}

.gap-evn-1 {
  gap: 2rem;
}

.w-btn{ text-align: center;
  margin-left: 30px;
  margin-top: 50px; }

  .entrl-w-btn{ text-align: center;
    margin-left: 30px;
    margin-top: 55px; }


.watchnow-btn {
  display: flex;
  justify-content: center;
  padding: 20px 40px;
  background-color: #1AB78D;
  color: #fff;
  border-radius: 105px;
  margin-top: 10px;

}


.watchnow-btn:hover {  
  background-color: #01437b; 
}

.watchnow-btn p { font-size: 20px; 
  font-weight: bold;}


@media screen and (max-width: 992px) {
  .evenet-m-name {
    font-size: 24px;
    line-height: 30px;
  }

  .evenet-m-subname {
    font-size: 20px;
    line-height: 28px;
  }
}

.speaker-img{ width: 310px; height: 310px;}

.entrl-btn-text{ font-size: 18px;}

.day-text { font-size: 23px;}

@media screen and (max-width: 640px) {
  .evenet-m-name {
    font-size: 22px;
    line-height: 27px;
  }

  .day-text { font-size: 20px;}

  .day-px-10 { padding: 0.5rem 1rem ;}


  .evenet-m-subname {
    font-size: 18px;
    line-height: 26px;
  }

  .gap-evn-1 {
    gap: 1rem;
  }
  .speaker-img{ width: 100%; height: 100%;}
  .w-en-full { width: 100%;
  }
  .enrl-mob-block { display: block !important;}
  .m-entrl-center { text-align: center;}
  .watchnow-btn { display: block; margin: auto;}

  .entrl-w-btn{ text-align: center;
    margin-left:0px;
    margin-top: 30px; }
    .watchnow-btn {    
      padding: 15px 30px;
    }
}

.w-h-30{width: 42px; height: 40px; padding: 12px;}

.img-45 { width: 40px;}

.enrld{ display: block; text-align: center; margin: auto;}

.p-all-20{ padding-top: 20px; padding-bottom: 20px;}

@media(min-width:769px) and (max-width:992px) {
  .hightlight-tab {
    margin-top: 0px;
  }
}

@media screen and (max-width: 768px) {
.evenet-m-subname{ padding-left: 0px;}
.ml-9 { margin-left: 0px;}
.m-full-w{ width: 100%;}
.pt-16 { padding-top: 2rem;}
.mm-t-15 { margin-top: 20px;}
.m-pl-10{ padding-left: 1.5rem;}

.m-m-pl-0 { padding-left: 0px !important;}
}