@media(min-width:1024px) and (max-width:1279px) {
  .count-tab-image {
    top: 230px !important;
  }
}

@media (min-width: 768px) {
  .hidedesk{ display: none;}
 }

 .top-30 {  top: 180px;}
 .pt-14 {
  padding-top: 4.5rem;
}

.overhiden {overflow: hidden;}
.speciality-text { line-height: 27px; padding-top: 20px;}
 @media (max-width: 768px) {
     .card-content {
       padding: 1rem;  
     }
 
     .hidemob { display: none;}
 
     .text-\[67px\] {
       font-size: 50px;
     }
     .pt-14 {
       padding-top: 1.5rem;   
     }
     .pl-14 {
       padding-left: 1.4rem;
     }
     .pbm-20 { padding-bottom: 20px;}
     .h-\[340px\] {
       height: 220px;
     }
     /* .top-30 {  top: 45px;}   */
     .speciality-text { line-height: 26px; padding-top: 20px;} 
     .p-m-10 { padding-top: 20px !important; padding-right: 1.5rem !important;}
   }

  

   .pall-member { padding: 50px 30px;}

.pleft-14 { padding-left: 20px;}
.plleft-5 { padding-left: 5px;}

 
@media (max-width: 640px) {
  .pall-member { padding: 30px 30px;} 
}
   