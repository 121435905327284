.video-responsive {
    height:auto ;
    width: 100%;
    display: block;
    
   
    position: relative;
    
}

.w-40 { width: 40%; float: left;}
.w-60 { width: 60%; float: left;}

.video-responsive iframe {
    left: 0;
    top: 0;    
    border-radius: 20px;
}
.inlinee{
    float: left;
    width:100px;
    height: 100px;
}

.fileattach{
    float:right
}
.fileattach {
    margin: 20px;
}

.attachment-link {
    display: block;
    padding: 10px;
    margin-bottom: 5px;
    color: #007bff;
    text-decoration: none;
}

.attachment-link:hover {
    text-decoration: underline;
}

.file-preview {
    margin: 20px;
    padding: 20px;
    background-color: #fff;
    border: 1px solid #ddd;
}

.event-name-title{color: #00549A; font-size: 36px; font-weight: bold; line-height: 46px; text-transform: uppercase;}
.event-name-subtitle{color: #303030; font-size: 24px;  line-height: 32px; position: relative; padding-top: 20px; padding-bottom: 30px;}

.event-box-p8 {padding: 2rem;}
.submit-f-head { color:#222; font-size: 34px; font-weight: 500; line-height: 40px;}

@media screen and (max-width: 1024px) {
.w-60 {
    width: 100%;
    float: none; 
  }
  .mob-wid-100 { width: 100%; margin-top: 20px; margin-bottom: 20px;}
}
@media screen and (max-width: 768px) {
.event-name-title{font-size: 30px; line-height: 40px;}
.event-name-subtitle{font-size: 22px; }
.block-768{ display: block;}
.m-top-10 { margin-top: 20px;}
.submit-f-head { color:#222; font-size: 28px; font-weight: 500; line-height: 40px;}
}
.event-box-p8 {padding: 1rem;}

* {
    box-sizing: border-box;
}

.wrapper-table {
    display: flex;
    overscroll-behavior-x: contain;
    overflow-x: auto;
    margin-inline: calc(var(--page-gutter) * -1);
    padding-inline: var(--page-gutter);   
    width: 100%;
    border: 1px solid lightgray;
}

table {
    border-spacing: 0;
    overflow: hidden;
    inline-size: 100%;
    text-align: left;
    background-color: inherit;
    border: 1px solid lightgray;
    border-radius: 0.5rem;
}

:is(th, td) {
    padding: 1rem;
    min-inline-size: 10rem;
    border-block-end: 1px solid lightgray;
    text-align: center;
}

tfoot :is(th, td) {
    border-block-end: unset;
}

tfoot tr {
    background-color: whitesmoke;
}

:is(th, td):not(:first-child) {
    border-inline-start: 1px solid lightgray;
}

.y-video { width: 100%; height: auto; min-height: 400px;}

.visually-hidden {
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
}

.gap-f-20 { gap: 2rem;}

@media(min-width:1024px) {
    .events-details {
        align-items: center;
    }
    .single-tooltip-box {
        right: 0;        
    }
    .single-tooltip-arrow {
     left: unset;
     right: 25px;
    }
}

@media(min-width:769px) and (max-width:1023px) {
    .events-details {
        display: flex;
        flex-direction:column
    }
    .speakers-details-head {
        display: flex;
        justify-content:left;
    }
    .speakers-details {
        margin-top: 20px;
    }
}

@media screen and (max-width: 768px) {
.y-video {  min-height: 300px;}
}

@media screen and (max-width: 520px) {
    .y-video { width: 100%; height: auto; min-height: 200px;}
    }


    @media(max-width:768px) {
        .sm\:justify-end {
            justify-content: start;
    }
    
}

@media(max-width:431px) {
    .speaker-photo {
        height: 3rem;
        width: 3rem;
    }
}


 