.datepicker1.datess {
    width: 100% !important;
}

.react-datepicker-wrapper {
    display:block !important;
    padding: 0;
    border: 0;
    width: 100% !important;
}
