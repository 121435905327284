.active-link {
    background-color: #F00; /* Your active link background color */
    color: #FFF; /* Your active link text color */
}

.dropdowncms-menu {
    display: block; 
    /* background-color: #d31b1b !important; */
    /* border: 1px solid #ddd; */
    border-radius: 4px;
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
    padding: 10px;
}


.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    margin-top: 1.5rem;
}

.pagination-arrow {
    background-color: #00549A;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 0.5rem 1rem;
    font-size: 1.2rem;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s;
}

.pagination-arrow:hover {
    background-color: #00549A;
    transform: scale(1.1);
}

.pagination-arrow.disabled {
    background-color: gray;
    cursor: not-allowed;
}

.pagination-info {
    font-size: 1rem;
    color: #58585a;
}

.pagination-icon {
    font-size: 1.5rem;
}

