.grading{ position: relative;}
.grading::after{
    position: absolute;
    content: '';
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 32.5%, #000 100%);
    height: 300px;
 
    z-index: 1;
    width: 100%;
    bottom:0;
    border-bottom: 8px;
    
}

.apos-side {
    position: fixed;
    overflow: scroll;
    height: 100vh;
}

.nam-width { width:100%;}
@media screen and (min-width:1600px) {
.nam-width { width: 300px;}
}

