.tcenter { text-align: center !important;}
.button-bg { background-color: #1AB78D; transition: all 0.4s ease-out 0s;}
.button-bg:hover { background-color: #00549a; transition: all 0.5s ease-out 0s;} 

@media screen and (min-device-width: 992px) and (max-device-width: 1200px) { 
.p-12 {
    padding: 2rem;
  }
}

@media screen and (max-width:992px) { 

    .mob-block { display: block;}
    .p-12 {
        padding: 3rem 2rem;
      }
      .p-12-mob{ padding: 2rem 1rem !important;}  
      .p-12-mob1{ padding: 0rem 1rem !important; padding-bottom: 1rem;}     
}

.registerbtn{ font-size: 17px;}

@media(max-width:768px) {
  .register-text {
    padding-top: 0.5rem;
  }
}