@media(min-width:768px) and (max-width:1023px) {
    .rounded-\[36px\] {
        border-radius: 55px;
    }
}

@media(min-width:520px) and (max-width:767px){
    .rounded-\[36px\] {
        border-radius: 45px;
    }
}