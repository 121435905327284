@media screen and (min-device-width: 1280px) and (max-device-width: 1366px) {    
    .pl-16 {
        padding-left: 1rem;
      } 
}

@media screen and (min-device-width: 992px) and (max-device-width: 1280px) {    
    .pl-16 {
        padding-left: 1rem;
      } 

      
  w-\[29\%\] {
    width: 100% !important;
  }

  .flex-col {
    display: block !important;
  }
  .w-\[29\%\] {
    width: 100%;
  }
  .w-\[280px\] {
    width: 100%;
  }

  .mob-dblock { display: block !important; padding:20px ;}
.mob-top20 { margin-top: 30px;}
}

.pall-20{ padding: 30px 40px;}



@media (max-width: 992px) {
.mob-dblock { display: block !important; padding:20px 0px ;}
.mob-top20 { margin-top: 30px;}
.pl-16 {
    padding-left: 0rem;
  }

  w-\[29\%\] {
    width: 100% !important;
  }

  .flex-col {
    display: block !important;
  }
  .w-\[29\%\] {
    width: 100%;
  }
  .w-\[280px\] {
    width: 100%;
  }

  .pall-20 {
    padding: 20px 10px;
  }

  .w-\[71\%\] {
    width: 100%;
  }
}

.forum-img { border-radius: 150px; max-width: 180px;}

.memberbg {border-bottom: 4px solid rgb(0, 84, 154);
    background-color: rgb(240, 250, 255);}

    .pr-24 {
        padding-right: 3rem;
      }

      .pl-12 {
        padding-left: 2rem;
      }

      .p-all-2-0 { padding-left: 20px; padding-right: 20px;}


@media(min-width:1281px) and (max-width:1535px) {
  .forum-members-box {
    margin-right: 40px;
  }
}