.grad {
    position: relative;
}

.grad::after {
    position: absolute;
    content: '';
    background: linear-gradient(180deg, rgba(0, 84, 154, 0.00) 0%, rgba(0, 84, 154, 0.64) 65.5%, #1AB78D 100%);
    height: 500px;
    z-index: 1;
    width: 100%;
    bottom: 0;
    border-bottom-right-radius: 30px;
}

.bgblue {
    background-color: #00549A;
    transition: all 0.5s ease;
}

.bgblue:hover {
    background-color: #1AB78D;
    transition: all 0.5s ease;
}