.dot-before::before {
    content: "•";  
    margin-right: 5px;  
}
.shadow-equal {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1) !important;
  }
  .rotate-icon {
    transform: rotate(180deg);
    transition: transform 0.3s ease;  
  }

  .bg-gry { background-color:#F4F4F4;}

  .bg-blue:hover { background-color: #cacaca;}
  .w-30 { width: 80px;}
  .eventcol { display: flex; align-items: center; justify-content: space-between;}
  .livebtn { font-size: 17px;}

  .event-heading { font-size: 24px;}

  .enrolled-events-details {
    margin-left: auto;
    width: 100%;
  }

  .event-btns {
    padding-left: 0.8rem;
    padding-right: 0.8rem;
  }

  @media screen and (min-device-width: 1279.5px) and (max-device-width: 1560px) {
    .livebtn { font-size: 13px;}
    .event-heading { font-size: 20px;}
    .w-43 { width: 43%;}
  }

  .event-heading1{ font-size: 18px;}

  .p-pro-8-all {padding: 1rem 2rem;}

  @media screen and (max-width:1279.5px) {
  .mobflex { display: block;}
  .mob-w-100 { width: 100%;}
  .pro-mobile-block{ display: block !important;}
  .desktop-full-width{ width: 100%;}
  }
  @media screen and (max-width:480px) {
  .mob-s-none{ display: none;}
  }

  .fonteighteen{ font-size: 18px;}

  @media (max-width: 1279.5px) {
    .events-items-date {
      justify-content: start;
    }
    .events-items-date .pxx-44 {
      margin-left: 5px;
      margin-right: 5px;
    }
    .fonteighteen{ font-size: 16px;}
   
  }
  @media (max-width:768px) {
  .m-block-menu .p-rm-0 {
    padding-right: 0;
  }
  .menu-mobile .pr-2{padding-right: 0;}

  .m-block-user-box{ display: block !important;}
  .pl-0-mob { padding-left: 0; margin-top: 15px;}
}

.p-personal { padding: 1rem 2rem;}

.font-21 { font-size: 21px;}
.font-16 { font-size: 16px;}

.text-24 { font-size: 24px;}
@media (max-width:768px) {
.p-personal { padding: 0.5rem 1rem;}
.font-21 { font-size: 18px;}
.font-16 { font-size: 14px;}
}

.podcast-pad-15{ padding: 1rem 1.5rem;}

.event-calendar-head {
  font-size: 27px;
}

@media (max-width:480px) {
.mob-center{ text-align: center;}
.mobile-p-6 { padding: 0.7rem !important;}
.user-img img { text-align: center; margin: 20px auto !important;}
.user-block{ display: block !important;}
.w-100-mob { width: 100%; margin: 10px 0px;}
.pl-0-m { padding-left: 0px;}
.m-mob-mb-20 { margin-bottom: 20px;}
.text-24 { font-size: 20px;}
/* .mob-text-left { text-align: left !important;} */

.profilename {
  font-size: 22px;
}

.p-p-all {
  padding: 2rem 1rem;
}

.p-8-mob-pod { padding: 1rem !important;}

}

@media(min-width:1690px) and (max-width:1780px) {
  .event-tab-text {
    font-size: 15px;
  }
}

@media(min-width:1536px) and (max-width:1550px) {
  .calendar-tabs {
    font-size: 17px;
  }
}

@media(min-width:1551px) and (max-width:1689px) {
  .enrolled-events-head {
    font-size: 23px;
  }
  .event-tab-text {
    font-size: 14px;
  }
}

@media(min-width:1500px) and (max-width:1550px) {
  .enrolled-events-head {
    font-size: 25px !important;
  }
  .event-btns {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}

@media(min-width:1413px) and (max-width:1550px) {
  .enrolled-events-head {
    font-size: 27px;
  }
  .event-tab-text {
    font-size: 15px;
  }
}


@media(min-width:1280px) {
  .events-img {
    width: 140px !important;
  }
  .event-calendar-head {
    padding-top: 1rem;
  }
}

@media(min-width:1280px) and (max-width:1499px) {
  .events-calendar {
    display: flex;
    flex-direction: column;
  }
  .enrolled-events-tab, .event-calendar-tab {
    width: 100%;
  }
}

@media(min-width:1280px) and (max-width:1328px) {
  .events-items-date .pxx-44 {
    width: 120px;
    margin: 3px 0px;
  }
}

@media(min-width:641px) and (max-width:768px) {
  .user-details {
    margin-top: -30px;
  }
}

@media(min-width:641px) and (max-width:692px) {
  .events-items-date .pxx-44 {
    width: 120px;
    margin: 3px 0px;
  }
  .calendar-tab {
    padding-left: 0px !important;
    padding-right: 0px !important;
    margin-left: 5px !important;
    margin-right: 5px !important;
  }
}

@media(max-width:640px) {
  .user-details {
    padding-bottom: 10px;
  }
}

@media(min-width:520px) and (max-width:553px) {
  .events-items-date .pxx-44 {
    width: 120px;
    margin: 3px 0px;
  }
}

@media(min-width:1280px) and (max-width:1412px) {
  .enrolled-events-head {
    font-size: 23px;
  }
  .event-tab-text {
    font-size: 15px;
  }
}

@media(min-width:1281px) {
  .event-content-text {
    font-size: 20px;
  }
  .event-date-btn {
    padding: 0.6rem 1rem;
  }
}

@media(min-width:1024px) and (max-width:1184px) {
  .edit-profile-btn {
    font-size: 18px;
  }
}

@media(min-width:769px) and (max-width:1023px) {
  .event-date-btn {
    padding: 0.6rem 1rem;
  }
  .event-content-text {
    font-size: 20px;
  }
}

@media(min-width:800px) and (max-width:1023px) {
  .edit-profile-btn {
    font-size: 16px;
  }
}

@media(max-width:799px) {
  .edit-profile-btn {
    font-size: 13px;
  }
}

@media(max-width:768px) {
  .event-date-btn-cont {
    justify-content: start;
    display: flex;
  }
}

@media(min-width:670px) {
  .enrolled-events-head {
    padding-right: 10px;
  }
}

@media(min-width:641px) and (max-width:768px) {
.dob-field, .email-phone-fields {
  justify-content: left;
  display: flex;
  text-align: left;
}
}

@media(min-width:641px) {
  .edit-profile-btn {
    width: 100%;
  }
  .events-history-tab {
    display:flex;
    justify-content: end;
  }
}

@media(min-width:641px) and (max-width:679px) {
  .enrolled-events-head {
    font-size: 22px;
  }
}

@media(min-width:481px) and (max-width:640px) {
  .user-image {
    margin: 20px 0px !important;
  }
}

@media(min-width:481px) and (max-width:1279px) {
  .events-img {
    width: 150px;
  }
}

@media(min-width:481px) and (max-width:484px) {
  .event-btns {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}

@media(min-width:481px) and (max-width:768px) {
  .enrolled-events-details {
    text-align: left;
  }
}

@media(min-width:481px) and (max-width:640px) {
  .profile-img {
    display: flex;
    justify-content: center;
  }
  .user-name-tab {
    justify-content: center;
  }
}

@media(max-width:480px) {
  .user-name-tab {
    justify-content: center;
  }
  /* .dob-field {
    justify-content: center;
    display: flex;
  } */
}

 

  