/* CSS */
.border-transparent {
  border-color: transparent !important;
}

/* CSS */
/* .carousel-arrow {
    display: none !important;

    mm-t20
} */

.pall-20 {
  padding: 20px;
}

@media(min-width:1280px) and (max-width:1536px){
  .pb-deskk-20 {
    padding-bottom: 0px;
  }
}

@media screen and (max-width: 1023px) {
  .mm-t20 {
    padding-top: 30px;
  }

  .mob-flex {
    display: block !important;
  }

  .mb-mt20 {
    padding-top: 20px;
  }

  .mob-pb-20 {
    padding-bottom: 20px;
  }
}

@media screen and (max-width: 767px) {
  .pt-m3 {
    margin-top: 10px;
  }

  .pt-12 {
    padding-top: 1.2rem;
  }

  .pl-12 {
    padding-left: 1.2rem;
    padding-right: 1.2rem;
  }

  .mborder {

    padding-top: 0px;
    padding-bottom: 20px;
  }

  .mob-pb-20 {
    padding-bottom: 5px;
    padding-top: 0px;
  }

  .m-fullwidth {
    margin-left: 3px !important;
    margin-bottom: 5px;
  }

}

@media screen and (max-width: 580px) {
  .m-fullwidth {
    width: 100% !important;
    margin-left: 0px !important;
    display: block;
    margin-bottom: 5px;
  }

}

@media screen and (max-width: 540px) {


  .mtp10 {
    margin-top: 0px;
    margin-left: 0px !important;
  }

  .pl-8 {
    padding-left: 1.5rem;
  }

  .text-\[19px\] {
    font-size: 15px;
    /* text-align: justify; */
  }

  .text-\[16px\] {
    font-size: 11px;
  }
}

.img-responsive {
  width: 100%;
  max-width: 100%;
  height: auto;
}


.carousel-container {
  padding-top: 0rem;
  width: 100%;
  height: 330px;
}

@media (min-width: 1025px) {
  .carousel-container {
    height: 360px;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .carousel-container {
    height: 360px;
  }
}

@media (max-width: 992px) {
  .carousel-container {
    padding-top: 2rem;
  }
}



@media (max-width: 640px) {
  .carousel-container {
    height: 315px;
  }

  .m-padding {
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
}


@media (min-width: 641px) and (max-width: 768px) {
  .carousel-container {
    height: 320px;
  }
}

@media (max-width: 480px) {
  .carousel-container {
    height: 250px;
  }
}






.pall-p-20 {
  padding: 20px;
}

@media (min-width:1280px){
.pall-15 {
  padding: 15px 20px 10px 20px;
}
}
@media (max-width:1280px){
  .pall-15 {
    padding: 15px 20px 20px 20px;
  }
}

.eventhead {
  color: #fff;
  font-size: 32px;
  font-weight: 600;
  padding-top: 0px;
  padding-bottom: 15px;
  display: flex;
}

.w-120 {
  width: 96px;
  text-align: right;
}

.news-text-width {
  width: 80%;
}

.p-ll-88 {
  padding-left: 1rem;
}

/* .pb-deskk-20 {
  padding-bottom: 20px;
} */

.event-head { font-size: 32px; font-weight: 700;}

@media (max-width: 600px) {
  .mob-blockdiv1 {
    align-items: start !important;
  }

  .mob-blockdiv {
    display: block;
    align-items: start !important;
  }

  .w-120 {
    width: 100px;
    text-align: left;
  }

  .news-text-width {
    width: 100%;
  }

  .pb-deskk-20 {
    padding-bottom: 0px;  }

  .mb-m-2 { margin-bottom: 12px;}
}

@media screen and (max-width: 1279px) {
  .mob-flex222 {
    display: block !important;
  }

  .m-w-1000{ width: 100% !important;}
  .mt-m-10 { margin-top: 20px;}
}




@media (min-width: 640px) {
  .home-sm\:h-64 {
    height: 20rem;
  }
}

@media (min-width: 768px) {
  .home-md\:h-72 {
    height: 23rem;
  }
}

@media (min-width: 992px) {
  .home-lg\:h-72 {
    height: 23rem;
  }
}



@media (min-width: 1024px) {
  .home-lg\:h-72 {
    height: 32rem;

  }
 
}

@media (min-width: 1280px) {
  .home-xl\:h-80 {
    height: 20rem;
  }
}

@media (min-width: 1366px) {
  .home-xl\:h-80 {
    height: 21rem;
  }
}

@media (min-width: 1536px) {
  .home-xl\:h-80 {
    height: 22rem;
  }
}
@media (max-width: 1280px) {
.mar-top-events{margin-top: 20px;}
}

@media (max-width: 640px) {
  .home-sm\:h-64 {
    height: 20rem;
  }
}

@media (max-width: 600px) {
  .home-sm\:h-64 {
    height: 18rem;
  }
}

@media (max-width: 520px) {
  .home-sm\:h-64 {
    height: 16rem;
  }
}

@media (max-width: 480px) {
  .home-sm\:h-64 {
    height: 16rem;
  }
}

@media (max-width: 420px) {
  .home-sm\:h-64 {
    height: 12rem;
  }
}

@media (max-width: 380px) {
  .sm\:h-64 {
    height: 12rem;
  }
}

/* Define the animation keyframes */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Apply animation to the news items */
.news-item {
  animation: fadeIn 0.5s ease-out;
}

/* Add a delay based on the index to create a staggered effect */
.news-item:nth-child(1) { animation-delay: 0.1s; }
.news-item:nth-child(2) { animation-delay: 0.2s; }
.news-item:nth-child(3) { animation-delay: 0.3s; }
.news-item:nth-child(4) { animation-delay: 0.4s; }
.news-item:nth-child(5) { animation-delay: 0.5s; }
.carousel .slide {
  transition: transform 0.5s ease-in-out;
}

/* Optionally, you can add a fade effect to slides */
/* Card2.css */

/* Custom styles for carousel transitions */
.carousel .slide {
  transition: transform 0.5s ease-in-out;
}

.carousel .slide img {
  transition: opacity 0.5s ease-in-out;
}

@media(min-width:1400px) and (max-width:1535px) {
  .event-sliders {
    padding: 15px 20px 0px 20px;
  }
}

@media(min-width:1281px) and (max-width:1399px) {
  .event-sliders {
    height: 445px;
  }
}

@media(min-width:601px) and (max-width:767px) {
  .new-events {
    padding-top: 1rem;
  }
}

@media(min-width:421px) and (max-width:465px ){
  .event-sliders {
    padding: 15px 20px 0px 20px;
  }
}

@media(min-width:340px) and (mx-width:370px) {
  .event-sliders {
    padding: 15px 20px 10px 20px;
  }
}

