.account-list {
    position: absolute;
    top: 45px;
    left: 80%;
    transform: translateX(-50%);
    background-color: #fff;
    z-index: 100;
    border-radius: 10px;
    box-shadow: 0px 0px 36px 0px rgba(0, 0, 0, 0.10);
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
    width: 290px;
}
.menu-list {
    position: absolute;
    top: 45px;
    left: 35%;
    transform: translateX(-50%);
    background-color: #fff;
    z-index: 100;
    border-radius: 10px;
    box-shadow: 0px 0px 36px 0px rgba(0, 0, 0, 0.10);
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
    width: 290px;
}
.account-list.show {
    opacity: 1;
    visibility: visible;
}
.menu-list.show {
    opacity: 1;
    visibility: visible;
}
.about-us-button {
    cursor: pointer;
}

.drop-menu li {
    font-weight: normal;
    padding: 10px 25px;
}

a {
    -webkit-transition: all 0.5s ease-out 0s;
    -moz-transition: all 0.5s ease-out 0s;
    -ms-transition: all 0.5s ease-out 0s;
    -o-transition: all 0.5s ease-out 0s;
    transition: all 0.5s ease-out 0s;
    color: var(--pbmit-link-color-normal);
    text-decoration: none;
}

.drop-menu li a {
    font-size: 12px;
    font-weight: 600;
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    letter-spacing: 0.3px;
    color: #262527;

}

.drop-menu li a:before {
    position: absolute;
    content: "";
    left: 0;
    right: auto;
    top: 50%;
    bottom: auto;
    transform: translateY(-50%);
    height: 1px;
    background-color: #181a17;
    transition: all 0.3s ease-out 0s;
}

.drop-menu li:hover a:before {
    width: 12px;
}

.drop-menu li a:hover {
    padding-left: 12px;
}

.drop-menu {
    min-width: 280px;
}

.account-list .drop-menu li a:hover {
    padding-left: 20px;
}

.account-list .drop-menu li:hover a:before {
    width: 12px;
}

.account-list .drop-menu li a:before {
    position: absolute;
    content: "";
    left: 0;
    right: auto;
    top: 50%;
    bottom: auto;
    transform: translateY(-50%);
    height: 1px;
    background-color: #181a17;
    transition: all 0.3s ease-out 0s;
}

.account-list .drop-menu li a {
    font-size: 12px;
    font-weight: 600;
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    letter-spacing: 0.3px;
    color: #262527;

}

.account-list .drop-menu li {
    font-weight: normal;
    padding: 10px 25px;
}

.my-account-btn {
    border-radius: 105px;
    padding: 14px 20px;
    font-weight: normal;
    margin-left: 30px;
}

.font-17 {
    font-size: 17px;
}

.menu2 {
    filter: brightness(0) invert(1);
}

.prr-14 { padding-right: 0rem;}

.just-end{ justify-content: end;}

@media screen and (max-width:1279.5px) {
    .m-block-menu {
        display: block;
    }
    .mob-width-100 { width: 100% !important;}
    .mobile-only{ width: 100%;}
    .just-end{ justify-content: space-between;}

    .p-rm-0 {
        padding-right: 1rem;
    }

    .my-account-btn { margin-left: 0px !important;}
}
@media screen and (max-width:430px) {
.mobile-only .gap-8 {
    gap: 1rem !important;
  }
  .my-account-btn.gap-4 {
    gap: 0.5rem !important;
  }
  .my-account-btn {
    border-radius: 105px;
    padding: 12px 14px;
  }

  .my-account-btn .font-17 {
    font-size: 13px;
  }
}

/* .drop-down-menu{margin-bottom: 0px !important;} */

.aboutuser-list {
    position: absolute;
    top: 82px;
    left: 29%;
    transform: translateX(-50%);
    background-color: #fff;
    z-index: 100;
    border-radius: 15px;
    box-shadow: 0px 0px 36px 0px rgba(0, 0, 0, 0.10);
    /* padding: 20px; */
    display: none;
    width: 15%;
  }
  
  .aboutuser-list.show {
    display: block;
  }


  .forumuser-list {
    position: absolute;
    top: 52px;
    left:76%;
    transform: translateX(-50%);
    background-color: #fff;
    z-index: 100;
    border-radius: 15px;
    box-shadow: 0px 0px 36px 0px rgba(0, 0, 0, 0.10);
    /* padding: 20px; */
    display: none;
    width: 330px;
  }
  
  .forumuser-list.show {
    display: block;
  }

  .bloguser-list {
    position: absolute;
    top: 82px;
    left:63%;
    transform: translateX(-50%);
    background-color: #fff;
    z-index: 100;
    border-radius: 15px;
    box-shadow: 0px 0px 36px 0px rgba(0, 0, 0, 0.10);
    /* padding: 20px; */
    display: none;
    width: 330px;
  }
  
  .bloguser-list.show {
    display: block;
  }

  .nav-shadow { -webkit-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.13);
    -moz-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.13);
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.13);}

    @media(max-width:1279px) {
        .h-mob-nav{display: none;} 
        .nav-shadow {
            box-shadow: 0px 0px 0px 0px rgba(0,0,0,0.13);
          }
    }

    @media screen and (max-width:1280px) {
    
    .mb-pad-20{ padding: 10px;}
    .mb-pad-0{ padding: 0px;}

    }

    @media(min-width:1537px) and (max-width:1690px) {
        .nav-items {
            padding-left: 2rem;
            padding-right: 2rem;
        }
    }