.profile-container {
    position: relative;
}

.blur-background {
    filter: blur(8px);
    pointer-events: none;
}

/* .form-popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px 30px;
    z-index: 1000;
    border: 1px solid #ccc;
    width: 100%;
    max-width: 1000px;
    overflow-x: hidden;
    overflow-y: auto;
    height: auto;
    transition: opacity .15s linear;  
} */
.form-popup {
    position: fixed;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    overflow: hidden;
    outline: 0;
    z-index: 1072;
    overflow-y: auto;
}



@media (min-width: 992px) {
    .form-content {
        max-width: 900px;
        margin: 1.75rem auto !important;
    }
}

.form-content {
    position: relative;
    width: auto;
    margin: .5rem;
    pointer-events: none;
}

.modal-content {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: .3rem;
    outline: 0;
    padding: 20px;
}

.user-img {
    border-radius: 8px;
    overflow: hidden;
}

.user-img img {
    width: 227px;
    border-radius: 8px;
}
 

.user-dash-profile img {
    width: 227px;
    border-radius: 8px;
}

.profilename {
    color: #000;
    font-size: 28px;
    font-weight: 600;
    line-height: 1.4;
}

.profile-detailbox {
    width: 100%;
    margin-bottom: 24px;
}

.profilebox {

    margin-top: 40px;
}

.line-1 {
    border-bottom: 1px solid #F4F4F4;
}

@media screen and (max-width:1279.5px) {
    .main-block {
        display: block;
    }

    .w-full-desk {
        width: 100%;
    }

    .sidebardiv,
    .pos-side {
        display: none;
    }

    .pos {
        position: relative;
        left: 0%;
    }

    .navdiv {
        width: 100%;
    }

    .profilebox {
        display: block;
        margin-top: 30px;
    }
}

@media screen and (max-width:992px) {

    .mob-l-p-30 {
        padding-left: 30px;
    }

    .mm-t-10 {
        margin-top: 30px;
    }

    .pt-m-10 {
        padding: 1rem 0px;
    }

   

    .mmb-10 {
        margin-top: 20px;
    }


}

@media(max-width:767px) {
    .modals-sections {
        padding-left: 0px;
    }
    .lastname-labels {
        padding-top: 2rem;
    }
}



@media screen and (max-width:640px) {

    .mm-block {
        display: block !important;
    }

    .m-block {
        display: block;
    }

    .mpl-0 {
        padding-left: 0px;
    }

    .m-h-auto {
        height: auto;
    }

    .mpl-0 {
        padding-left: 0px;
        padding-top: 0px;
    }

    .mm-t-0 {
        padding-top: 10px;
    }

    .mb-top-20 {
        padding-top: 1rem;
    }

    .user-dash-profile{padding-left: 30px; padding-top: 30px;}
    .mm-mt-15{ margin-top:15px ;}
}


@media screen and (max-width:475px) {


    .profilebox {
        display: block;
        margin-top: 30px;
    }
}

.pall20 {
    padding: 20px;
}

.mt-50 {
    padding-top: 50px;
}

.profile-edit-btn {
    background-color: #00549A;
    width: 200px;
    text-align: center;
    margin: auto;
    padding: 20px 30px;
    border-radius: 6px;
}

.close-btn {
    position: absolute;
    right: 31px;
}

.input-file {
    border: 1px solid #dcdcdc;
    border-radius: 6px;
}

.pro-img-edit {
    width: 120px;
    border-radius: 4px;
    border: 1px solid #c0c0c0;
    padding: 3px;
}

@media screen and (max-width:992px) {
    .mob-p-8-1 .p-8 {padding: 1.5rem;}
}

.right-444{ right: -1rem;}
@media screen and (max-width:640px) {
.right-444{ left: 5px; bottom: 5px;}
}

@media screen and (max-width:768px) {
    .mob-p-8-1 .p-8 {padding: 1rem;}
}

@media(max-width:640px) {
    .profile-heading-tab {
        justify-content: center;
    }
}


@media(min-width:486px) and (max-width:500px) {
    .my-profile-headings {
        padding: 1rem 0rem !important;
    }
}

@media screen and (max-width: 485px) {
    .mob-p-8-1 .p-8 {
      padding: 1rem 0rem;
    }

    .mob-m-p-6{padding: 0.8rem !important;}
    .mob-m-p-66{padding: 0.8rem !important;}
    
  }

  @media(min-width:381px) and (max-width:392px) {
    .my-profile-heading {
        font-size: 14px;
    }
  }

  @media(max-width:380px) {
    .my-profile-heading {
        font-size: 12px !important;
    }
  }

  @media screen and (max-width: 380px) {
    .mob-text .text-\[18px\] {
        font-size: 12px !important;
      }      
  }

  @media screen and (max-width: 370px) {
    .mob-text .text-\[18px\] {
        font-size: 11px !important;
      }      
  }

/* @media screen and (max-width:768px) {
.form-popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px 30px;
    z-index: 1000;
    border: 1px solid #ccc;
    width: 100%;
    max-width: 1000px;
    overflow-x: hidden;
    overflow-y: auto;
    height: auto;
    transition: opacity .15s linear;  
}

} */

.total-potn-bg img{ width: 100%; max-width: 100%; height: auto;}
 
@media screen and (max-width: 768px) {
.point-item {   
    display: block !important;    
}
.point-number { margin-top:20px ;}
}