.px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 0.4rem;
    padding-bottom: 0.4rem;
}

.p-66 {
    padding: 1.2rem;
}

.w-fullsection {
    width: 84%;
}

.wh-60 {
    width: 62px;
    height: 62px;
    padding-top: 6px;
    margin-top: 8px;
}

.eventcontent {
    padding-right: 10px;
    width: 100%;
}

.gap-2 {
    gap: 1rem;
}

.event-item {
    margin-bottom: 10px;
    padding-bottom: 10px;
}

.eventitems a {
    border-bottom: 1px solid #CBCBCB;
    width: 100%;
    display: block;
}

.eventitems a:last-child {
    border: none;
}

.pall {
    padding: 20px;
}

.p-6 {
    padding: 1.4rem;
}

.eventdate {
    padding: 0.6rem 1.3rem;
    width: 115px;
}

@media (max-width:992px) {
    .text-\[18px\] {
        font-size: 15px;
    }

    .text-\[17px\] {
        font-size: 15px;
    }

    .mt-10 {
        margin-top: 10px;
    }
}
@media (max-width:768px) {
.m-font-20{ font-size: 20px;
}

@media (max-width:480px) {
  .m-font-20{ font-size: 18px;
  }
  .m-font-18 {
    font-size: 16px;
  }
}

.m-font-18{ font-size: 18px;
}

.m-b-block-100 { display:  block !important;}
.eventdate {
    padding: 0.5rem 1rem !important;
    margin-top: 8px;
  }

  .w-fullsection{ width: 100% !important;}
  .eventcontent {padding-right: 0px;}

  .wh-60 {
    width: 60px;
    height: 60px;
    padding-top: 6px;
    margin-top: 8px;
}

.wh-70 { width: 70px;}

.m-text-18 { font-size: 18px;}

}

.evn-cal-btn { font-size: 16px;}
.m-pt-14 { padding-top: 10px;}

@media (max-width:520px) {
    .m-640-none {
        display: none;
    }
}






  @media (min-width: 640px) {
    .sm\:h-64 {
      height: 20rem;
    }
  }

  @media (min-width: 768px) {
    .md\:h-72 {
      height: 26rem;
    }
  }

  @media (min-width: 992px) {
    .lg\:h-72 {
      height: 28rem;
    }
  }

  

  @media (min-width: 1024px) {
    .lg\:h-72 {
      height: 32rem;
    }
  }

  @media (min-width: 1280px) {
    .xl\:h-80 {
      height: 20rem;
    }
  }

  @media (min-width: 1366px) {
    .xl\:h-80 {
      height: 21rem;
    }
  }

  @media (min-width: 1536px) {
    .xl\:h-80 {
      height: 25rem;
    }
  }

  @media (max-width: 640px) {
    .sm\:h-64 {
      height: 20rem;
    }
  }

  @media (max-width: 600px) {
    .sm\:h-64 {
      height: 18rem;
    }
  }

  @media (max-width: 520px) {
    .sm\:h-64 {
      height: 16rem;
    }
  }

  @media (max-width: 480px) {
    .sm\:h-64 {
      height: 16rem;
    }
  }

  @media (max-width: 420px) {
    .sm\:h-64 {
      height: 15rem;
    }
  }

  @media (max-width: 380px) {
    .sm\:h-64 {
      height: 12rem;
    }
  }


  @media screen and (min-device-width: 1024px) and (max-device-width: 1280px) {
  .m-font-20{ font-size: 18px !important;}
  .m-font-18 { font-size: 16px !important;}
  .m-b-block-100 { display: block !important;}
  .eventdate {
    padding: 0.5rem 1rem;
  }
  }

  @media(min-width:641px) and (max-width:692px) {
    .event-period-btn {
      padding-left: 1rem !important;
      padding-right: 1rem !important;
      margin-left: 5px !important;
      margin-right: 5px !important;
    }
  }

  .p-l-10 { padding-right: 15px;}

  .event-item .pl-4 {
    padding-left: 0.2rem !important;
  }

  .event-pad-r-l{ padding-left: 1.75rem; padding-right: 1.75rem; padding-top: 0.75rem; padding-bottom: 0.75rem;}
  @media (max-width: 380px) {
  .event-pad-r-l{ padding-left: 1.5rem; padding-right: 1.5rem; padding-top: 0.75rem; padding-bottom: 0.75rem;}
  }


.transition{
  
    transition: background-color 800ms ease;
  }
 