
.sidebardiv { width: 20%; float: left; }
.navdiv { width: 80%; float: left;}

.pos {
    position:relative;
    left: 20%;
} 
.pos-side {
    position:fixed
} 