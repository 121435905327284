@media(min-width:1400px) and (max-width:1535px) {
    .download-btn {
        width: 69%;
    }
}

@media(min-width:1280px) and (max-width:1399px) {
    .download-btn {
        width: 76%;
    }
}

@media(min-width:1024px) and (max-width:1279px) {
    .download-btn-text {
        font-size: 15px;
    }
    .download-btn {
        width: 100%;
    }
}


@media(min-width:768px) and (max-width:1023px) {
    .newsltrhead {
        font-size: 35px;
    }
    .download-btn-text {
        font-size: 15px;
    }
    .download-btn {
        width: 86%;
    }
}

@media(min-width:768px) and (max-width:991px) {
    .newsletter-main-cont {
        height: 330px;
    }
    .newltrcont {
        padding-top: 7rem;
    }
}

@media(max-width:768px) {
    .news-letter-heads {
        padding-top: 1rem;
    }
}

@media(max-width:767px) {
    .newsltrhead {
        font-size: 25px;
        padding-bottom: 20px;
        line-height: 30px;
        padding-right: 10px;
    }
    .download-btn {
        width: 50%;
    }
    .download-btn-text {
        font-size: 13px;
        padding-top: 3px;
    }  
}

@media(max-width:540px) {
    .download-btn {
        width: 55%;
    }
}

@media(max-width:505px) {
    .download-btn {
        width: 60%;
    }
}

@media(max-width:476px) {
    .download-btn {
        width: 65%;
    }
}

@media(max-width:452px) {
    .download-btn {
        width: 75%;
    }
}

@media(max-width:415px) {
    .download-btn {
        width: 85%;
    }
}

@media(max-width:384px) {
    .download-btn {
        width: 100%;
    } 
}

.news-letter-tab {
    max-width: calc(100vw - 100px);
}