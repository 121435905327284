/* Gallery.css */
.custom-link {
    color: blue; /* Set link color */
    text-decoration: underline; /* Underline links */
}

h1 {
    font-size: 2em; /* Adjust heading 1 size */
}

h2 {
    font-size: 1.5em; /* Adjust heading 2 size */
}

strong {
    font-weight: bold; /* Make strong text bold */
}

em {
    font-style: italic; /* Italicize emphasized text */
}
