 
@media (min-width: 768px) {
    .grid-cols-1 lg\:grid-cols-2 {
        grid-template-columns: 1fr 1fr;  
    }
}

.newsletter-text { line-height: 26px; padding-bottom: 20px;}
 
@media (max-width: 992px) {
    .m-pt-12{ padding-top: 1.5rem !important;}
    .m-pt-8 { padding-top: 1rem;}
}
 
 
.card7-carousel .swiper-button-next,
.card7-carousel .swiper-button-prev {
    font-size: 18px;  
    width: 40px;  
    height: 40px; 
    background-color: #9ab4cd;  
    color: #efebeb; 
    border-radius: 50%;  
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 4px 6px rgba(240, 237, 237, 0.1);  
    transition: background-color 0.3s ease, box-shadow 0.3s ease;  
    border: white;
}

 
.card7-carousel .swiper-button-next:hover,
.card7-carousel .swiper-button-prev:hover {
    background-color: transparent;  
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2); 
    color: white;  
    border: white;
}

.swiper-button-prev, .swiper-rtl .swiper-button-next {  display: none !important;}
.swiper-button-next, .swiper-rtl .swiper-button-prev {display: none !important;}

 
.card7-carousel .swiper-button-next::after,
.card7-carousel .swiper-button-prev::after {
    font-size: 18px;  
}
.h340{ height: 340px;}

@media (max-width: 768px) {
.h340{ height: 320px;}
}

@media (max-width: 640px) {
    .h340{ height: 300px;}
    }

@media (max-width: 580px) {
    .h340{ height: 290px;}
    }

@media (max-width: 520px) {
    .h340{ height: 250px;}
    }

@media (max-width: 480px) {
    .h340{ height: 210px;}
    }
    

@media (max-width: 420px) {
    .h340{ height: 188px;}
    }
    

