.point-maindiv {
    border-radius: 8px;
    display: flex;
    gap: 1.5rem;
}

.pont-rightbox {
    width: 60%;
}

.pont-left {
    width: 40%;
}

.point-item {
    width: 100%;
    background-color: #FAFAFA;
    border: 1px solid #E0E0E0;
    border-radius: 6px;
    Padding: 12px 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
    margin-bottom: 12px;
}



.point-content p {
    font-weight: 400;
    font-size: 17px;
    line-height: 24px;
    color: #303030;
}

.point-content span {
    font-weight: 400;
    font-size: 12px;
    line-height: 24px;
    color: #686868;
}

.point-number {
    background-color: #00549A;
    color: #fff;
    text-align: center;
    padding: 7px 13px;
    font-weight: 600;
    font-size: 16px;
    border-radius: 6px;
}

.total-potn-bg {
    background-repeat: no-repeat;
    margin-top: 40px;
    position: relative;

}

.point-btn {
    text-align: center;
    color: #00549A;
    font-size: 15px;
    font-weight: 600;

}

.point-btn-div {
    padding: 10px 0px;
    width: 100%;
    border-top: 1px solid #E0E0E0;
    text-align: center;
}

.point-head {
    padding: 20px;
    padding-bottom: 0px;
}

.pall-10 {
    padding: 0px 20px;
}

.total-pont {
    position: absolute;
    bottom: 40px;
    right: 50px;
    width: 100px;
    height: 90px;
    text-align: center;
}

.total-pont p {
    font-size: 17px;
    color: #161E54;
    font-weight: 600;
}

.point-num {
    background-color: #FFCA0E;
    text-align: center;
    padding: 7px 26px;
    font-size: 36px;
    font-weight: bold;
    color: #000;
}

@media screen and (max-width:1023.5px) {
.point-maindiv {
    border-radius: 8px;
    display: block;
    gap: 1.5rem;
}
.pont-rightbox {
    width: 100%;
}
.pont-left {
    width: 100%;
    margin-bottom: 20px;
}
.mob-d-none{ display: none;}

}

@media screen and (min-width:1023.5px) {
    .desk-d-none{ display: none;}
}

@media screen and (max-width:480px) {
    .total-pont p {
        font-size: 16px;       
    }
    .point-num {
        background-color: #FFCA0E;
        text-align: center;
        padding: 6px 24px;
        font-size: 24px;
        font-weight: bold;
        color: #000;
    }

    .total-pont {       
        bottom: 5px;
        right: 15px;        
      }

}